// import { createStore } from 'vuex'
export default ({
    namespaced: true,
    state: {
        value: 0
    },
    getters: {
    },
    mutations: {
        showFooter(state: any, val: any) {
            state.value = val
        }
    },
    actions: {
    },
})
