export default ({
    namespaced: true,
    state: {
        select: ([
            {
                title: 'fag.ChangeEmail',
                message: 'fag.EmailAnswer',
                heading: 'headingOne',
                collapse: 'collapseOne',
                id: 0,
                parent: 0
            },
            {
                title: 'fag.ChangPassword',
                message: 'fag.Password1',
                heading: 'headingTwo',
                collapse: 'collapseTwo',
                id: 1,
                parent: 0
            },
            {
                title: 'fag.HackedAccount',
                message: 'fag.HackedAnswer',
                heading: 'headingThree',
                collapse: 'collapseThree',
                id: 2,
                parent: 0
            },
            {
                title: 'fag.HaveAccounts',
                message: 'fag.HaveAccountsAnswer',
                heading: 'headingFour',
                collapse: 'collapseFour',
                id: 3,
                parent: 0
            },
            {
                title: 'fag.BecomeAdvertiser',
                message: 'fag.BecomeAdvertiserAnswer',
                heading: 'headingFive',
                collapse: 'collapseFive',
                id: 4,
                parent: 1
            }, {
                title: 'fag.MakeDeposit',
                message: 'fag.MakeDepositAnswer',
                heading: 'headingSix',
                collapse: 'collapseSix',
                id: 5,
                parent: 1
            },
            {
                title: 'fag.ProvideSupport',
                message: 'fag.ProvideSupportAnswer',
                heading: 'headingSeven',
                collapse: 'collapseSeven',
                id: 6,
                parent: 1
            },
            {
                title: 'fag.ProvideAssistance',
                message: 'fag.ProvideAssistanceAnswer',
                heading: 'headingEight',
                collapse: 'collapseEight',
                id: 7,
                parent: 1
            },
            {
                title: 'fag.AdFormats',
                message: 'fag.AdFormatsAnswer',
                heading: 'headingNine',
                collapse: 'collapseNine',
                id: 8,
                parent: 1
            },
            {
                title: 'fag.Difference',
                message: 'fag.DifferenceAnswer',
                heading: 'headingTen',
                collapse: 'collapseTen',
                id: 9,
                parent: 1
            },
            {
                title: 'fag.SpecificCountries',
                message: 'fag.SpecificCountriesAnswer',
                heading: 'headingEleven',
                collapse: 'collapseEleven',
                id: 10,
                parent: 1
            },
            {
                title: 'fag.GoLive',
                message: 'fag.GoLiveAnswer',
                heading: 'headingTwelve',
                collapse: 'collapseTwelve',
                id: 11,
                parent: 1
            },
            //隐藏
            {
                title: 'fag.GetRefund',
                message: 'fag.GetRefundAnswer',
                heading: 'headingThirteen',
                collapse: 'collapseThirteen',
                id: 12,
                parent: 1
            },
            {
                title: 'fag.Pulish1',
                message: 'fag.Pulish1Mess',
                heading: 'headingFourteen',
                collapse: 'collapseFourteen',
                id: 13,
                parent: 2
            },
            {
                title: 'fag.Pulish2',
                message: 'fag.Pulish2Mess',
                heading: 'headingFifteen',
                collapse: 'collapseFifteen',
                id: 14,
                parent: 2
            },
            {
                title: 'fag.Pulish3',
                message: 'fag.Pulish3Mess',
                heading: 'headingSixteen',
                collapse: 'collapseSixteen',
                id: 15,
                parent: 2
            },
            {
                title: 'fag.Pulish4',
                message: 'fag.Pulish4Mess',
                heading: 'headingSeventeen',
                collapse: 'collapeSeventeen',
                id: 16,
                parent: 2
            },
            {
                title: 'fag.Pulish5',
                message: 'fag.Pulish5Mess',
                heading: 'headingEighteen',
                collapse: 'collapseEighteen',
                id: 17,
                parent: 2
            },
            {
                title: 'fag.Pulish6',
                message: 'fag.Pulish6Mess',
                heading: 'headingNineteen',
                collapse: 'collapseNineteen',
                id: 18,
                parent: 2
            },
            {
                title: 'fag.Pulish7',
                message: 'fag.Pulish7Mess',
                heading: 'headingTwenty',
                collapse: 'collapseTwenty',
                id: 19,
                parent: 2
            },
            {
                title: 'fag.Pulish8',
                message: 'fag.Pulish8Mess',
                heading: 'headingTwentyone',
                collapse: 'collapseTwentyone',
                id: 20,
                parent: 2
            },

        ]),
        GeneralDate: [],
        Advertisers: [],
        Publishers: []
    },
    getters: {
    },
    mutations: {
        Advertisers(state: any) {
            state.Advertisers = state.select.filter((item: any) => item.parent == 1)
        },
        GeneralArr(state: any) {
            state.GeneralDate = state.select.filter((item: any) => item.parent == 0)
        },
        Publishers(state: any) {
            state.Publishers = state.select.filter((item: any) => item.parent == 2)
        },

    },
    actions: {
    },
})
