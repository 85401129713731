const es = {
  // 导航栏
  nav: {
    Advertisers: 'Anunciantes',
    Publishers: 'Editor',
    Marketplace: 'mercado',
    Blog: 'blog',
    SignUp: 'Registrarse',
    SignIn: 'Iniciar sesión',
    Lang: "idioma",
    Lang1: 'English',
    Lang2: "中文(简体)",
    Lang3: "日语",
    Lang4: "español",
    Langfr: "Français",
    Langzhtw: "中文(繁體)",
  },
  advertisers: {
    Setup: 'Configure fácilmente su campaña de publicidad gráfica',
    Min: 'Pasos mínimos requeridos',
    Deliver: 'Proporciona publicidad financiera y de criptomonedas',
    SeeBtn: 'Ver nuestros formatos de anuncios',
    Across1: 'Desarrolle su estrategia publicitaria para mostrar sus anuncios de criptomonedas al público objetivo adecuado y conectarse con numerosos sitios web de la industria.',
    Across2: 'Al utilizar nuestras herramientas de seguimiento y optimización, puede mejorar rápidamente su rendimiento de marketing, mientras que un administrador de campaña se asegurará de que sus anuncios se publiquen sin problemas.',
    Chosen: 'Elegido por la mayoría de los anunciantes en el mercado de las criptomonedas.',
    MainFocus: 'Centrada en el rendimiento, la publicidad trabaja con los principales sitios web financieros y de criptomonedas para garantizar que sus anuncios estén dirigidos de manera efectiva a la audiencia adecuada.',
    TheBanner: 'Para obtener los mejores resultados de su campaña de marketing, es fundamental colocar su banner en la parte más visible de su sitio web.',
    CreateBtn: 'Registrarse como anunciante',
    Benefits: 'Beneficios de convertirse en anunciante',
    IfPublisher: "Si eres editor, puedes registrarte aquí",
    Earning: '¡Gane dinero ahora!',
    Weekly: 'Pago semanal/mensual',
    Message1: 'Reciba pagos automáticamente cada semana o cada mes, según sus preferencias.',
    Eff: 'Publicidad eficiente',
    Message2: 'Maximice la monetización de su tráfico independientemente de la geografía.',
    CPM: 'modelo de pago CPM',
    Message3: 'Aumente fácilmente sus ingresos optimizando los ingresos por cada 1000 impresiones.',
    Non: 'Experiencia no invasiva',
    Message4: "No ahuyentes a tus usuarios con anuncios mal diseñados. Nuestros banners garantizan una experiencia de usuario no intrusiva.",
    Payout: 'Múltiples opciones de pago',
    Message5: 'Retire sus fondos de forma gratuita mediante BTC, ETH, USDT (ERC20) o transferencia bancaria (SEPA/SWIFT).',
    Reach: "Explorar nuevos horizontes",
    Contact: 'Contáctenos',
    Salas: 'Equipo de Ventas',
    Send: 'Déjanos un mensaje',
    Impressions: 'impresión',
    Clicks: 'Clics',
    Websites: 'sitio web',
    Permonth: 'Todos los meses',
    dataSetup:'Configure su campaña de publicidad gráfica en minutos',
    dataSetupsub:'Proporciona publicidad financiera y criptográfica',
    dataSetuptext:'Cree su campaña y entregue anuncios criptográficos a la audiencia adecuada en cientos de sitios web de la industria',
    dataSetuptext1:'Nuestras herramientas de seguimiento y optimización lo ayudarán a mejorar sus campañas, y su administrador de campañas de Coinzilla garantizará que sus anuncios criptográficos se ejecuten sin problemas de principio a fin',
    dataSetup2:'Elegido por más del 50% de los anunciantes en el mercado criptográfico',
    dataSetuptext2:'Nuestra principal preocupación es el rendimiento. Tenemos contratos exclusivos con los principales sitios web financieros y de criptomonedas para ayudar a que sus anuncios lleguen a la audiencia adecuada',
    dataSetuptext3:'Colocar banners en los lugares más destacados de su sitio web garantizará los mejores resultados para sus campañas publicitarias',
  },
  publishers: {
    Earn: 'Mejore los ingresos de su sitio web',
    Across1: 'Nuestras herramientas y soluciones están diseñadas para aumentar sus ingresos manteniendo al mismo tiempo una experiencia de usuario no disruptiva.',
    Across2: 'Nuestra plataforma le permite crear una variedad de formatos de anuncios, como anuncios nativos y anuncios de banner, lo que le permite mostrarlos fácilmente en su sitio web',
    Chosen: "No lo dudes, únete a nosotros ahora",
    MainFocus: 'ADX es una de las redes de publicidad financiera y de criptomonedas más amplias y continúa ampliando su base de editores.',
    TheBanner: 'Utilice nuestro sistema de publicidad para aumentar fácilmente los ingresos de su sitio web y maximizar sus ingresos',
    CreateBtn: 'Registrarse como editor',
    Let: 'Permitir que los anunciantes compitan por su espacio publicitario'
  },
  marketplace: {
    Buy: 'Comprar comunicado de prensa de criptomonedas',
    Artic: 'y artículos patrocinados',
    Your: 'Un mercado para todas sus necesidades de contenido',
    Across1: 'Distribuya su contenido en sitios web acreditados en la industria financiera y de criptomonedas para obtener la visibilidad que necesita',
    Across2: 'Nuestros sitios asociados le permiten desarrollar una estrategia de relaciones públicas eficaz dentro de limitaciones financieras',
    GetStrat: '¡Más información!',
    Let: 'Permitir que los profesionales generen',
    Content: 'Contenido preparado para ti',
    Pu: 'Publica tus comunicados de prensa un 70% más rápido',
    Across3: 'Nuestro equipo creará un comunicado de prensa único basado en su solicitud y lo distribuirá a través de nuestra red de sitios web de socios criptográficos',
    Across4: 'Los comunicados de prensa se publicarán rápidamente, lo que le ahorrará la necesidad de un seguimiento posterior del editor',
    Across5: 'No se preocupe si un editor decide no publicar su comunicado de prensa. Recibirás un reembolso completo, sin excepciones',
    Across6: 'Tan pronto como se publique su comunicado de prensa, nuestro equipo le proporcionará un comprobante de entrega, lo que le permitirá monitorear el progreso de su campaña',
    We: 'Supervisamos la distribución de sus comunicados de prensa sobre criptomonedas',
    Will: "Proporcionaremos actualizaciones continuas a lo largo de este proceso",
    Deliver: 'Publique comunicados de prensa sobre criptomonedas fácilmente',
    Submit: 'Enviar pedido en unos minutos',
    Across7: 'Haga su pedido, pague una vez y acceda a sus blogs y sitios de cifrado favoritos',
    Across8: 'Procese eficientemente todos los comunicados de prensa a través de una interfaz unificada, ahorrando tiempo y energía valiosos',
    Increased: 'Seguridad de transacciones mejorada',
    Coinzilla: 'ADX proporciona servicios de custodia',
    Across9: 'Puede recibir notificaciones en tiempo real sobre la difusión de comunicados de prensa, lo que le permitirá evaluar su impacto cuando sea necesario. Además, tenga la seguridad de que no hay necesidad de preocuparse por posibles estafas.',
    Across10: 'Nuestro sistema de seguridad protege a compradores y vendedores, eliminando cualquier riesgo de contracargos',
    Across11: 'Gracias a nuestra extensa red de blogs y sitios web sobre criptomonedas, podemos difundir comunicados de prensa sobre una variedad de temas',
    Across12: 'Además, tienes la opción de ordenar y filtrar los sitios web que aceptan tu newsletter con antelación',
    Large: 'varios tipos',
    Gam: 'Incluyendo juegos de azar, intercambios, deportes electrónicos, etc.',
  },
  coinzillae: {
    Boost: 'Impulsa tu proyecto',
    Boost2: 'Tiene una red publicitaria cifrada superior',
    Boost3: 'Confíe en la red de publicidad de criptomonedas para el éxito del proyecto',
    Ready: 'Nuestra red superior de publicidad en criptomonedas lo impulsará a nuevas alturas en fintech',
    Ready2: '',
    Launch: 'Iniciar tu actividad',
    On: 'Anuncie en los principales sitios web financieros y de criptomonedas',
    Pu: 'Más de 16.000 campañas ejecutadas con éxito',
    Across1: 'Posiciona tu marca de manera efectiva para llegar a tu audiencia ideal',
    Across2: 'Tenemos asociaciones sólidas con sitios web acreditados en el ámbito financiero y de las criptomonedas. Como resultado, tendrá acceso a ofertas exclusivas y ubicaciones de anuncios premium que lo diferenciarán de sus competidores.',
    Across3: 'Examine una amplia gama de dimensiones y diseños de anuncios para determinar el mejor plan de marketing para usted. Diseñe anuncios atractivos de criptomonedas, identifique su audiencia deseada y controle efectivamente su gasto aprovechando los datos de rendimiento en tiempo real',
    Check: 'Explore nuestros formatos de anuncios',
    Customize: 'Personaliza planes publicitarios y financieros para adaptarlos a tus necesidades',
    Take: 'Control total de tus planes',
    Across4: 'Nuestras herramientas de seguimiento y optimización le ayudarán a mejorar la eficacia de su marketing y su administrador de campaña se asegurará de que sus anuncios se publiquen sin problemas de principio a fin',
    Chosen: 'Elegido por más del 65% de los anunciantes de criptomonedas',
    Result: 'Promoción basada en resultados',
    Self: 'Actividades autogestionadas',
    Message1: 'Supervise y personalice fácilmente sus campañas de display',
    Cam: 'Rendimiento de campaña mejorado',
    Message2: 'Alcance los objetivos publicitarios sin dejar de ser rentable',
    Html: 'Anuncio de banner HTML5 personalizado',
    Message3: 'nteractúe con su audiencia a través de banners HTML5 personalizados',
    Flex: 'Plan de gasto adaptable',
    Message4: 'Ajustar la distribución de anuncios en función del gasto y los resultados',
    Str: 'Publicidad de posicionamiento estratégico',
    Message5: 'Colocar banner para máxima visibilidad',
    Your: 'Beneficios del anunciante',
    If: "¿Es usted editor? ¡Regístrese ahora!",
    Adver: 'Anuncio de hoy',
    Say: 'Comentarios de los clientes',
    Setup: 'Configure su campaña publicitaria de criptomonedas en minutos',
    Reach: 'Atraer millones de nuevos clientes',
    Across5: 'Nuestras herramientas de seguimiento y optimización le ayudarán a mejorar la eficacia de su marketing, mientras que su administrador de campaña se asegurará de que sus anuncios se publiquen sin problemas de principio a fin.',
    tagMessage1: '¡Estamos entusiasmados de trabajar con el gran equipo de ADX! Demuestran profesionalismo y puntualidad excepcionales, entregando consistentemente excelentes resultados. Sus servicios aumentan en gran medida la exposición que necesitan los proyectos criptográficos y se esfuerzan constantemente por lograr la excelencia en su trabajo',
    tagMessage2: 'Gracias a nuestra amplia experiencia y valiosos conocimientos en la gestión de campañas publicitarias en ADX, Student Coin se ha conectado con éxito con una gran cantidad de inversores globales. En sólo 12 meses desde nuestra fundación, estamos orgullosos de estar entre las 300 principales criptomonedas del mundo por capitalización de mercado',
    tagMessage3: 'Student Coin tiene una amplia experiencia y soporte en la gestión de campañas publicitarias a través de ADX, lo que ha construido con éxito una gran base de inversores global. En sólo 12 meses desde nuestra fundación, nos hemos clasificado entre las 300 principales criptomonedas por capitalización de mercado.',
    title1: "A. CEO de Wall Street Game",
    title2: "Daniel Director de marketing",
    title3: "Daniel Director de marketing",
    data1:"Conviértase en uno de nuestros anunciantes exitosos. Confiando en nuestras asociaciones únicas con plataformas financieras y de criptomonedas líderes, así como en asesoramiento profesional y asistencia personalizada, sus planes de marketing producirán rápidamente resultados extraordinarios",
  },
  foot: {
    abouttitle: 'Acerca de nosotros',
    about1: 'Acerca de nosotros',
    about2: 'Conozca a nuestros socios',
    about3: 'Contáctenos',
    SERVICEStitle: 'Servicios',
    services1: 'ADX del anunciante',
    services2: 'ADX del editor',
    services3: 'Mercado ADX',
    HELPtitle: 'Ayuda',
    help1: 'blog',
    help2: 'Preguntas frecuentes',
    help3: 'Descargar kit de medios',
    help4: 'Nuestros formatos de anuncios',
    SOCIALPRESENCEtitle: 'Cooperación Social',
    social1: 'Facebook',
    social2: 'Twitter',
    social3: 'Youtube',
    social4: 'LinkedIn',
  },
  contact: {
    sendTitle: '¡Déjanos un mensaje y comencemos!',
    sendMessage1: 'Si está listo para comenzar a trabajar juntos, háganoslo saber. Envíanos un mensaje utilizando nuestro formulario de contacto y nos comunicaremos contigo lo antes posible. ',
    sendMessage2: 'Si cree que uno de nuestros editores o anunciantes ha violado su privacidad o confidencialidad, o si es testigo de un comportamiento que viola nuestros términos y condiciones, le recomendamos encarecidamente que lo informe a través de nuestra herramienta de denuncia de abuso. ',
    sendTitle2: 'Herramienta para informar abuso',
    contactTeam: 'Contacta con nuestro equipo',
    reportAbuse: 'Reportar Abuso',
    form1title1: 'Tipo de cuenta',
    form1input1: "Soy anunciante",
    form1input12: "Yo soy el editor",
    form1title2: 'nombre',
    form1input2: "Tu nombre completo",
    form1title3: 'dirección de correo electrónico',
    form1input3: "Su dirección de correo electrónico",
    form1title4: 'Mensajería instantánea',
    form1input4: "Skype, Telegram o número de teléfono (opcional)",
    form1title5: 'Información',
    form1input5: "Escribe aquí tu pregunta o consulta...",
    submit: 'Envía tu mensaje',
    form2title1: 'Tipo de informe',
    form2input1: "Editor/Sitio web",
    form2input12: "URL del anunciante/página de destino",
    form2input13: "Anuncio de banner",
    form2input14: "Violación de protección de datos",
    form2title2: 'Violando la URL de la página/sitio web',
    form2title3: 'Captura de pantalla/URL de prueba',
    form2input3: "https://prnt.sc/...(opcional)",
    form2title4: 'Información',
    form2input4: "Escribe tu mensaje aquí para ayudarnos a entender mejor el tipo de informe...",
    AvailableSizes: 'Tallas disponibles:',
    Highlights: 'énfasis',
  },
  //常见问题
  fag: {
    General: 'universal',
    Advertisers: 'Anunciantes',
    Publishers: 'Editores',
    //General
    Help: '¿Cómo podemos ayudarte?',
    SearchAnswer: 'Pregunta de búsqueda',
    ChangeEmail: '¿Puedo cambiar mi dirección de correo electrónico?',
    EmailAnswer: 'Solo puede cambiar su dirección de correo electrónico realizando una solicitud a nuestro equipo a través de la dirección de correo electrónico utilizada actualmente en su cuenta.',
    ChangPassword: '¿Cómo cambio mi contraseña?',
    Password1: 'Puedes hacer esto yendo a la pestaña Configuración en el panel',
    changPassword: 'Cambiar contraseña',
    Password2: 'sección para cambiar contraseña.',
    HackedAccount: '¿Qué debo hacer si creo que mi cuenta ha sido pirateada?',
    HackedAnswer: 'Si tiene motivos para creer que su cuenta ha sido comprometida, cambie su contraseña inmediatamente y comuníquese con su administrador de cuentas.',
    HaveAccounts: '¿Puedo tener varias cuentas?',
    HaveAccountsAnswer: 'No, no se permiten varias cuentas.',
    //Advertisers
    BecomeAdvertiser: '¿Cómo me convierto en anunciante?',
    BecomeAdvertiserAnswer: 'Para convertirse en anunciante, debe registrarse para obtener una cuenta. Luego nos comunicaremos con usted para discutir todos los detalles de su evento.',
    MakeDeposit: '¿Cómo deposito?',
    MakeDepositAnswer: 'Puede realizar un depósito a través de la herramienta de financiación de depósitos en la sección Facturación del panel.',
    ProvideSupport: '¿Ofrecen soporte para las actividades de configuración?',
    ProvideSupportAnswer: 'Sí, nuestro equipo lo ayudará durante todo el proceso de creación del evento. Esto abarca desde asesorarle sobre cómo optimizar su campaña hasta diseñar sus banners para obtener mejores resultados.',
    ProvideAssistance: '¿Ofrecen asistencia con el diseño de banners?',
    ProvideAssistanceAnswer: 'Sí. Nuestros diseñadores están listos para crear tu banner a tu gusto.',
    AdFormats: '¿Qué formatos de anuncios están disponibles?',
    AdFormatsAnswer: 'Algunos de los formatos de anuncios disponibles incluyen banners clásicos, banners de encabezado, banners adhesivos y anuncios nativos. Puede ver nuestra lista completa de formatos de anuncios.',
    AdFormatsAnswer2: 'Aquí.',
    Difference: "¿Cuál es la diferencia entre conocimiento de marca y tráfico de calidad?",
    DifferenceAnswer: "El paquete Premium ofrece tráfico a sitios web premium desde el nicho criptográfico. Esto también se refleja en el CPM de ambos paquetes, con un CPM de $0,20 para el tráfico de reconocimiento de marca.",
    SpecificCountries: '¿Puedo orientar mis anuncios a países específicos?',
    SpecificCountriesAnswer: 'Sí, puede dirigirse a su audiencia según el país o el dispositivo.',
    GoLive: '¿Cuánto tiempo tarda mi evento en publicarse?',
    GoLiveAnswer: 'Después del envío, su campaña se publicará dentro de 1 día hábil. Sin embargo, los fines de semana o días festivos, su campaña puede tardar hasta 48 horas en publicarse.',
    GetRefund: '¿Cómo obtengo un reembolso?',
    GetRefundAnswer: 'Para obtener información sobre nuestra política de reembolso, consulte nuestra',
    GetRefundAnswer2: 'Términos y condiciones.',
    //Publishers
    Pulish1: '¿Cómo puedo convertirme en editor? ',
    Pulish2: '¿Cuántos sitios web puedo agregar? ',
    Pulish3: '¿Qué tipo de sitios web se aceptan? ',
    Pulish4: '¿Cuáles son sus tarifas? ',
    Pulish5: '¿Cuáles son las tarifas por retirar dinero? ',
    Pulish6: '¿Pagaré en función de los clics o las impresiones? ',
    Pulish7: '¿Puedo pedir a mis usuarios que hagan clic en un anuncio? ',
    Pulish8: '¿Por qué obtengo menos impresiones en mi panel que en otras redes? ',

    Pulish1Mess: 'Para convertirse en editor, debe registrarse para obtener una cuenta y enviar su sitio a través del panel de editor. Una vez aprobado, comenzaremos a mostrar el banner en su sitio.',
    Pulish2Mess: 'No hay límite para la cantidad de sitios web en los que puede anunciarse.',
    Pulish3Mess: 'Como red de publicidad financiera y de criptomonedas, ADX solo acepta sitios web y proyectos relacionados con criptomonedas en su red.',
    Pulish4Mess: 'Las tarifas se basan en la calidad del sitio web. Durante este proceso, tenemos en cuenta diversos factores como el diseño del sitio web, la calidad del contenido, etc.',
    Pulish5Mess: 'Sin comisiones por retiro.',
    Pulish6Mess: 'Tenemos un modelo de pago de costo por cada mil impresiones (CPM) tanto para anuncios nativos como de banner.',
    Pulish7Mess: 'No. Los usuarios sólo deben interactuar con los anuncios si están interesados ​​en ellos. Exigir a sus usuarios que hagan clic en los anuncios resultará en que los anunciantes paguen por tráfico de baja calidad y puede resultar en su exclusión de nuestra red.',
    Pulish8Mess: 'Como editor, usted gana dinero cada vez que un usuario ve un banner colocado en su sitio. Esto significa que si el banner se coloca en la parte inferior de la página y el visitante no se desplaza tan lejos, la vista no contará. Esta medida se implementa para garantizar que los anunciantes solo paguen por ver sus anuncios cuando los usuarios interactúan con o.',

  },
  formats: {
    title: 'Nuestros formatos de anuncios',
    DisplayCampaign: 'Campaña de display',
    subtitlea: 'Nuestros banners clásicos ofrecen una forma sencilla de captar la atención de su público objetivo',
    Displayfeaturea: 'Mayor visibilidad',
    Displayfeatureb: 'Aceptar banner HTML5',
    Displayfeaturec: 'Modo de pago CPM',
    Displayfeatured: 'Porcentaje de clics promedio del 0,25% al ​​0,30%',
    CreateCampaign: 'Crear campaña',
    PopupAd: 'Publicidad intercalada',
    subtitleb: 'Anuncio en pantalla completa mostrado entre la carga de la página web',
    Nativefeaturea: 'Excelente ubicación',
    Nativefeatureb: 'Personalizable mediante API pública',
    Nativefeaturec: 'modelo de pago CPM',
    Anchoradvertising: 'publicidad ancla',
    subtitlec: 'Anuncio fijado en el borde superior o inferior de la página',
    Popunderfeaturea: 'Visualización rápida',
    Popunderfeatureb: 'Ideal para ofertas de suscripción única',
    Popunderfeaturec: 'modelo de pago CPM',
    Popunderfeatured: 'Promedio de 0,25% - 0,30% de tasa de clics',
    DisplayCampaignMessage: 'Mostrar descripción de la campaña',
    PopupAdMessage: 'Descripción de publicidad intercalada',
    AnchoradvertisingMessage: 'Descripción de la publicidad ancla',
    AvailableSizes: 'Tallas disponibles:',
    Highlights: 'énfasis',
  }
}
export default es