import { createStore } from 'vuex'
import footerShow from './footer/footer'
import lang from './lang/lang'
import faq from './Faq/faq'
export default createStore({
  state: {
    //是否进入市场
    isEnterMarket: false,
  },
  getters: {
  },
  mutations: {
    //修改是否进入市场
    changeIsEnterMarket(state, isEnterMarket) {
      state.isEnterMarket = isEnterMarket
    }
  },
  actions: {
  },
  modules: {
    footerShow, lang, faq
  }
})
