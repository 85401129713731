import { createRouter, RouteRecordRaw, createWebHashHistory } from "vue-router";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Main",
    redirect: '/ADX',
    component: () => import("../views/MainView.vue"),
    children: [
      {
        path: '/ADX',
        name: 'ADX',
        meta: {
          title: "ADX"
        },
        component: () => import('../views/Coinzillae.vue')
      },
      {
        path: '/advertisers',
        name: 'advertisers',
        meta: {
          title: "Advertisers"
        },
        component: () => import('../views/Advertisers.vue')
      },
      {
        path: '/publishers',
        name: 'publishers',
        meta: {
          title: "Publishers"
        },
        component: () => import('../views/Publishers.vue')
      },
      {
        path: '/marketplace',
        name: 'marketplace',
        meta: {
          title: "Marketplace"
        },
        component: () => import('../views/Marketplace.vue')
      },
      {
        path: '/all',
        name: 'all',
        meta: {
          title: "All"
        },
        component: () => import('../views/All.vue'),
        children: [
          {
            path: 'cookie-policy',
            name: 'cookie',
            meta: {
              title: "Cookie"
            },
            component: () => import('../views/all/CookiePolicy.vue')
          },
          {
            path: 'terms',
            name: 'terms',
            meta: {
              title: "Terms"
            },
            component: () => import('../views/all/Terms.vue')
          },
          {
            path: 'privacy-policy',
            name: 'policy',
            meta: {
              title: "Policy"
            },
            component: () => import('../views/all/PrivacyPolicy.vue')
          },
        ]
      },
      {
        path: '/contact',
        name: 'contact',
        meta: {
          title: "Contact"
        },
        component: () => import('../views/Contact.vue')
      },
      {//faq
        path: '/faq',
        name: 'General',
        meta: {
          title: 'FrequentlyAskedQuestions'
        },
        component: () => import("../views/faq/Faq.vue"),
        children: [
          {
            path: 'advertisers-faq',
            name: 'Advertisers-faq',
            meta: {
              title: 'FrequentlyAskedQuestions'
            },
            component: () => import("../views/faq/AdvertisersFaq.vue"),
          },
          {
            path: 'publishers-faq',
            name: 'publishers-faq',
            meta: {
              title: 'FrequentlyAskedQuestions'
            },
            component: () => import("../views/faq/PublishersFaq.vue"),
          }
        ]
      },
      {
        path: '/formats',
        name: 'formats',
        meta: {
          title: "Formats"
        },
        component: () => import('../views/AdFormats.vue')
      },
    ],
  },
  // {
  //   path: "/loginmain",
  //   name: "LoginMain",
  //   component: () => import("../views/login/LoginMain.vue"),
  //   children: [
  //     {
  //       path: "/userlogin",
  //       name: "UserLogin",
  //       component: () => import("../views/login/UserLogin.vue"),
  //     },
  //   ],
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  // linkActiveClass: "active-router",
  routes,
  // scrollBehavior(to, from, savedPosition) {

  //   if (to.hash) {
  //     return {
  //       el: to.hash,
  //       behavior: 'smooth',
  //     }
  //   } else {
  //     return {
  //       left: 0,
  //       top: 0,
  //       behavior: 'smooth'
  //     }
  //   }
  // },
});

export default router;
