"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) {
  return (0, vue_2.pushScopeId)("data-v-500bcb10"), n = n(), (0, vue_2.popScopeId)(), n;
};
var _hoisted_1 = {
  class: "itemNav pointer"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "colorNav"
};
exports.default = (0, vue_1.defineComponent)({
  __name: 'MessageNav',
  props: ['value'],
  setup: function (__props) {
    return function (_ctx, _cache) {
      return (0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [(0, vue_2.createElementVNode)("img", {
        src: __props.value.path == _ctx.$route.path ? __props.value.url2 : __props.value.url,
        alt: ""
      }, null, 8, _hoisted_2), (0, vue_2.createElementVNode)("div", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t(__props.value.lang)), 1)]);
    };
  }
});