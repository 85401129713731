const zhCN = {
  // 导航栏
  nav: {
    Advertisers: '广告商',
    Publishers: '发布商',
    Marketplace: '市场',
    Blog: '博客',
    SignUp: '注册',
    SignIn: '登录',
    Lang: "语言",
    Lang1: 'English',
    Lang2: "中文(简体)",
    Lang3: "日语",
    Lang4: "español",
    Langfr: "Français",
    Langzhtw: "中文(繁體)",
  },
  advertisers: {
    Setup: '轻松建立您的显示广告活动',
    Min: '只需最少的步骤',
    Deliver: '提供金融和加密货币广告',
    SeeBtn: '查看我们的广告格式',
    Across1: '制定您的广告策略，向合适的目标受众展示您的加密货币广告，并与众多行业网站建立联系。',
    Across2: '通过使用我们的跟踪和优化工具，您可以迅速提高营销效果，而活动经理将确保您的广告顺利运行。',
    Chosen: '被加密货币市场中的大多数广告商选中。',
    MainFocus: '专注于性能，广告与一流的金融和加密货币网站合作，确保您的广告有效地瞄准合适的受众。',
    TheBanner: '为了从营销活动中获得最佳效果，将横幅放在网站最显眼的部分是至关重要的。',
    CreateBtn: '注册成为广告商',
    Benefits: '成为广告商的好处',
    IfPublisher: "如果您是出版商，您可以在这里注册",
    Earning: '现在就赚钱！',
    Weekly: '周/月付款',
    Message1: '根据您的喜好，每周或每月自动获得报酬。',
    Eff: '高效的广告投放',
    Message2: '最大限度地提高您的流量货币化，无论其地理位置如何。',
    CPM: 'CPM支付模型',
    Message3: '通过优化每1000次印象的收入，轻松增加您的收入。',
    Non: '非侵入性体验',
    Message4: "不要通过设计拙劣的广告来赶走您的用户。我们的横幅保证了非侵入性的用户体验。",
    Payout: '多种支付选项',
    Message5: '通过BTC、ETH、USDT（ERC20）或银行电汇（SEPA/SWIFT）免费提取您的资金。',
    Reach: "探索新视野",
    Contact: '联系我们',
    Salas: '销售团队',
    Send: '给我们留言',
    Impressions: '印象',
    Clicks: '点击量',
    Websites: '网站',
    Permonth: '每月',
    dataSetup:'在几分钟内设置你的展示广告活动',
    dataSetupsub:'提供金融和加密广告',
    dataSetuptext:'建立您的广告活动，并在数百个行业网站上向正确的受众提供加密广告',
    dataSetuptext1:'我们的跟踪和优化工具将帮助您改进您的广告活动，您的Coinzilla活动经理将确保您的加密广告从头到尾顺利运行',
    dataSetup2:'被加密市场超过50%的广告商选择',
    dataSetuptext2:'我们主要关注的是性能。我们与顶级金融和加密货币网站签订独家合同，以帮助您的广告达到正确的受众',
    dataSetuptext3:'在网站最显眼的地方放置横幅可以保证你的广告活动产生最佳效果',
  },
  publishers: {
    Earn: '提高您网站的收益',
    Across1: '我们的工具和解决方案旨在提高您的收入，同时维护无中断的用户体验。',
    Across2: '我们的平台使您能够创建各种广告格式，如本地广告和横幅广告，让您轻松地在网站上展示它们',
    Chosen: "别犹豫，现在就加入我们吧",
    MainFocus: 'ADX是最广泛的金融和加密货币广告网络之一，不断扩大其出版商群体。',
    TheBanner: '利用我们的广告投放系统，轻松提高您网站的收入，让您实现收入最大化',
    CreateBtn: '注册成为出版商',
    Let: '允许广告商竞争您的广告空间'
  },
  marketplace: {
    Buy: '购买加密货币新闻稿',
    Artic: '和赞助文章',
    Your: '满足您所有内容需求的市场',
    Across1: '在加密货币和金融行业的知名网站上分发您的内容，以获得您所需的可见性',
    Across2: '我们的合作伙伴网站使您能够在财务限制范围内制定有效的公关策略',
    GetStrat: '学习更多!',
    Let: '允许专业人员生成',
    Content: '为您准备的内容',
    Pu: '将您的新闻稿出版速度提高70%',
    Across3: '我们的团队将根据您的要求制作独特的新闻稿，并在我们的加密合作伙伴网站网络中分发',
    Across4: '新闻稿将迅速发布，为您省去后续出版商跟进的需要',
    Across5: '如果出版商选择不发行您的新闻稿，不要担心。您将获得全额退款，没有任何例外',
    Across6: '一旦您的新闻稿上线，我们的团队将立即向您提供交付证明，让您能够监控您的活动进展',
    We: '我们监督您的加密新闻稿的分发',
    Will: "我们将在整个过程中提供持续更新",
    Deliver: '轻松发布加密货币新闻稿',
    Submit: '在几分钟内提交订单',
    Across7: '下单，完成一次付款，并访问您喜欢的加密网站和博客',
    Across8: '通过一个统一的界面高效处理所有新闻稿，节省宝贵的时间和精力',
    Increased: '增强的交易安全性',
    Coinzilla: 'ADX提供托管服务',
    Across9: '您可以收到关于新闻稿传播的实时通知，使您能够在需要时评估自己的影响力。另外，请放心，没有必要担心潜在的骗局',
    Across10: '我们的安全系统保护买家和卖家，消除任何退款风险',
    Across11: '由于我们广泛的加密货币博客和网站网络，我们可以传播各种主题的新闻稿',
    Across12: '此外，您可以选择对提前接受您的时事通讯的网站进行分类和筛选',
    Large: '种类繁多',
    Gam: '包括赌博、交易所、电子竞技等',
  },
  coinzillae: {
    Boost: '提升您的项目',
    Boost2: '拥有顶级加密广告网络',
    Boost3: '依靠加密货币广告网络获得项目成功',
    Ready: '我们卓越的加密货币广告网络将推动您在金融科技领域达到新的高度',
    Ready2: '',
    Launch: '启动您的活动',
    On: '在顶级金融和加密货币网站上做广告',
    Pu: '成功执行了16000多项活动',
    Across1: '有效定位您的品牌，以达到理想受众',
    Across2: '我们与金融和加密货币领域的知名网站建立了强有力的合作伙伴关系。因此，您将可以获得独家交易和高级广告植入，从而将您与竞争对手区分开来',
    Across3: '调查广泛的广告维度和布局，以确定最适合您的营销计划。设计引人入胜的加密货币广告，精确定位您想要的受众，并通过利用实时业绩数据有效控制您的支出',
    Check: '探索我们的广告格式',
    Customize: '根据您的需求量身定制广告和财务计划',
    Take: '全面掌控您的计划',
    Across4: '我们的跟踪和优化工具将帮助您提高营销效果，您的活动经理将确保您的广告从头到尾顺利运行',
    Chosen: '超过65%的加密货币广告商选择',
    Result: '成果驱动型促销',
    Self: '自我管理的活动',
    Message1: '轻松监督和定制您的展示活动',
    Cam: '增强的市场活动绩效',
    Message2: '在保持成本效益的同时实现广告目标',
    Html: '自定义HTML5横幅广告',
    Message3: '通过个性化的HTML5横幅与观众互动',
    Flex: '适应性支出计划',
    Message4: '根据支出和结果对广告分发进行微调',
    Str: '战略定位广告',
    Message5: '放置横幅以获得最大的可见性',
    Your: '广告商的好处',
    If: "您是出版商吗？立即注册！",
    Adver: '今日广告',
    Say: '来自客户的反馈',
    Setup: '在几分钟内设置您的加密货币广告活动',
    Reach: '吸引数百万新客户',
    Across5: '我们的跟踪和优化工具将帮助您提高营销效果，而您的活动经理将确保您的广告从开始到结束顺利运行。',
    tagMessage1: '我们很高兴能与ADX的杰出团队合作！他们表现出非凡的专业精神和守时精神，始终如一地取得优异成绩。他们的服务极大地提高了加密项目所需的曝光率，他们一直在努力实现卓越的工作',
    tagMessage2: '得益于我们在ADX上管理广告活动的丰富经验和宝贵见解，Student Coin成功地与大量全球投资者建立了联系。在我们成立的短短12个月内，我们自豪地跻身全球市值前300的加密货币之列',
    tagMessage3: 'Student Coin在通过ADX管理广告活动方面拥有丰富的专业知识和支持，成功地建立了庞大的全球投资者基础。在我们成立的短短12个月内，我们就跻身市值前300的加密货币之列.',
    title1: "A.华尔街游戏首席执行官",
    title2: "Daniel 首席营销官",
    title3: "Daniel 首席营销官",
    data1:"成为我们成功的广告商之一。依靠我们与领先的金融和加密货币平台的独特合作，以及专业建议和定制协助，您的营销计划将迅速产生非凡的成果",
  },
  foot: {
    abouttitle: '关于我们',
    about1: '关于我们',
    about2: '认识我们的合作伙伴',
    about3: '联系我们',
    SERVICEStitle: '服务',
    services1: '广告商的ADX',
    services2: '出版商的ADX',
    services3: 'ADX市场',
    HELPtitle: '帮助',
    help1: '博客',
    help2: '常见问题',
    help3: '下载媒体工具包',
    help4: '我们的广告格式',
    SOCIALPRESENCEtitle: '社交合作',
    social1: '脸书',
    social2: '推特',
    social3: 'Youtube',
    social4: '领英',
  },
  contact: {
    sendTitle: '给我们留言，让我们开始吧！',
    sendMessage1: '如果您准备好开始合作，请告诉我们。使用我们的联系表向我们发送消息，我们会尽快回复您。',
    sendMessage2: '如果您认为您的隐私或保密性遭到我们的发布商或广告商之一的侵犯，或者如果您目睹了违反我们条款和条件的行为，我们强烈建议您通过我们的报告滥用工具报告滥用行为。',
    sendTitle2: '报告滥用工具',
    contactTeam: '联系我们的团队',
    reportAbuse: '报告滥用',
    form1title1: '帐户类型',
    form1input1: "我是广告商",
    form1input12: "我是出版商",
    form1title2: '姓名',
    form1input2: "您的全名",
    form1title3: '电子邮件地址',
    form1input3: "您的电子邮件地址",
    form1title4: '即时通讯',
    form1input4: "Skype、电报或电话号码（可选）",
    form1title5: '信息',
    form1input5: "在这里写下您的问题或询问...",
    submit: '提交您的留言',
    form2title1: '报告类型',
    form2input1: "出版商/网站",
    form2input12: "广告商/着陆页 URL",
    form2input13: "横幅广告",
    form2input14: "违反数据保护规定",
    form2title2: '违规网站/页面 URL',
    form2title3: '截图/证明网址',
    form2input3: "https://prnt.sc/...（可选）",
    form2title4: '信息',
    form2input4: "在此写下您的消息，以帮助我们更好地了解报告类型...",
    AvailableSizes: '可用尺寸:',
    Highlights: '强调',
  },
  //常见问题
  fag: {
    General: '普遍',
    Advertisers: '广告商',
    Publishers: '出版商',
    //General
    Help: '我们如何可以帮助您？',
    SearchAnswer: '搜索问题',
    ChangeEmail: '可以更改我的邮箱地址吗？',
    EmailAnswer: '您只能通过您帐户当前使用的电子邮件地址向我们的团队提出请求来更改您的电子邮件地址。',
    ChangPassword: '怎么修改我的密码？',
    Password1: '您可以通过转到仪表板中设置选项卡下的',
    changPassword: '更改密码',
    Password2: '部分来更改密码。',
    HackedAccount: '我认为我的账户被黑了怎么办？',
    HackedAnswer: '如果您有理由相信您的帐户已被盗用，请立即更改密码并与您的客户经理联系。',
    HaveAccounts: '我可以有多个账户吗?',
    HaveAccountsAnswer: '不可以，不允许使用多个帐户。',
    //Advertisers
    BecomeAdvertiser: '我如何成为广告商？',
    BecomeAdvertiserAnswer: '为了成为广告商，您必须注册一个账户。然后我们会联系您讨论您活动的所有细节。',
    MakeDeposit: '我如何存款？',
    MakeDepositAnswer: '您可以通过存款资金工具在仪表板的账单部分进行存款。',
    ProvideSupport: '您是否为设置活动提供支持？',
    ProvideSupportAnswer: '是的，我们的团队将在整个活动创建过程中为您提供帮助。这从建议您如何优化您的活动，到设计您的横幅以获得最佳效果。',
    ProvideAssistance: '您是否提供横幅设计方面的帮助？',
    ProvideAssistanceAnswer: '是的。我们的设计师已准备好根据您的喜好创建您的横幅。',
    AdFormats: '有哪些可用的广告格式？',
    AdFormatsAnswer: '一些可用的广告格式包括经典横幅、标题横幅、粘性横幅和原生广告。您可以查看我们完整的广告格式列表。',
    AdFormatsAnswer2: '在这里。',
    Difference: "品牌知名度和优质流量有什么区别？",
    DifferenceAnswer: "高级套餐提供来自加密利基市场的高级网站的流量。这也反映在两个套餐的CPM中，品牌知名度流量的CPM为0.2美元。",
    SpecificCountries: '我可以针对特定国家吗？',
    SpecificCountriesAnswer: '是的，您可以根据国家或设备定位您的受众。',
    GoLive: '我的活动需要多长时间才能上线？',
    GoLiveAnswer: '提交后，您的广告系列将在1个工作日内上线。但是，在周末或假期，您的广告系列最多可能需要48小时才能上线。',
    GetRefund: '我怎么退款？',
    GetRefundAnswer: '有关我们退款政策的信息，请查看我们的 ',
    GetRefundAnswer2: '条款和条件。',
    //Publishers
    Pulish1: '我如何成为出版商？',
    Pulish2: '我可以添加多少个网站？',
    Pulish3: '什么样的网站被接受？',
    Pulish4: '您们的费率是多少？',
    Pulish5: '取款的费用是多少？',
    Pulish6: '我会根据点击次数或展示次数付费吗？',
    Pulish7: '我可以要求我的用户点击广告吗？',
    Pulish8: '为什么我在仪表板上获得的展示次数少于其他网络上的展示次数？',

    Pulish1Mess: '为了成为发布商，您必须注册一个帐户并通过发布商仪表板提交您的网站。一旦获得批准，我们将开始在您的网站上显示横幅。',
    Pulish2Mess: '您可以投放广告的网站数量没有限制。',
    Pulish3Mess: '作为一个金融和加密货币广告网络，ADX 只接受与加密货币相关的网站和项目加入其网络。',
    Pulish4Mess: '费率根据网站的质量而定。在此过程中，我们会考虑各种因素，例如网站设计、内容质量等。',
    Pulish5Mess: '没有提款费用。',
    Pulish6Mess: '我们为横幅广告和原生广告制定每千次展示费用 (CPM) 支付模式。',
    Pulish7Mess: '不可以。用户只有在对广告感兴趣时才应该与广告互动。要求您的用户点击广告将导致广告商为低质量的流量付费，并会导致您被禁止进入我们的网络。',
    Pulish8Mess: '作为发布商，每次用户查看您网站上放置的横幅时，您都会赚钱。这意味着，如果横幅放置在页面底部并且访问者没有滚动那么远，则该视图将不会计入。实施此措施是为了确保广告商仅在用户与或交互时付费查看他们的广告。',

  },
  formats: {
    title: '我们的广告格式',
    DisplayCampaign: '展示活动',
    subtitlea: '我们的经典横幅提供了一种吸引目标受众注意力的简单方法',
    Displayfeaturea: '较高的知名度',
    Displayfeatureb: '接受HTML5横幅',
    Displayfeaturec: 'CPM付费模式',
    Displayfeatured: '平均0.25% - 0.30%点击率',
    CreateCampaign: '创建营销活动',
    PopupAd: '穿插广告',
    subtitleb: '在网页加载间隙展示的全屏广告',
    Nativefeaturea: '很棒的位置',
    Nativefeatureb: '可通过公共API进行定制',
    Nativefeaturec: 'CPM付费模式',
    Anchoradvertising: '锚定广告',
    subtitlec: '固定在页面顶部或底部边缘的广告',
    Popunderfeaturea: '快速显示',
    Popunderfeatureb: '单一选择加入优惠的理想选择',
    Popunderfeaturec: 'CPM付费模式',
    Popunderfeatured: '平均0.25% - 0.30%点击率',
    DisplayCampaignMessage: '展示活动说明',
    PopupAdMessage: '穿插广告说明',
    AnchoradvertisingMessage: '锚定广告说明',
    AvailableSizes: '可用尺寸:',
    Highlights: '强调',
  }
}
export default zhCN