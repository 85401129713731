const zhTW = {
  // 导航栏
  nav: {
    Advertisers: '廣告商',
    Publishers: '發布商',
    Marketplace: '市場',
    Blog: '部落格',
    SignUp: '註冊',
    SignIn: '登入',
    Lang: "語言",
    Lang1: 'English',
    Lang2: "中文(简体)",
    Lang3: "日语",
    Lang4: "español",
    Langfr: "Français",
    Langzhtw: "中文(繁體)",
  },
  advertisers: {
    Setup: '輕鬆建立您的顯示廣告活動',
    Min: '只需最少的步驟',
    Deliver: '提供金融和加密貨幣廣告',
    SeeBtn: '查看我們的廣告格式',
    Across1: '制定您的廣告策略，向合適的目標受眾展示您的加密貨幣廣告，並與眾多行業網站建立聯繫。 ',
    Across2: '透過使用我們的追蹤和優化工具，您可以迅速提高行銷效果，而活動經理將確保您的廣告順利運行。 ',
    Chosen: '被加密貨幣市場中的大多數廣告商選中。 ',
    MainFocus: '專注於效能，廣告與一流的金融和加密貨幣網站合作，確保您的廣告有效地瞄準合適的受眾。 ',
    TheBanner: '為了從行銷活動中獲得最佳效果，將橫幅放在網站最顯眼的部分是至關重要的。 ',
    CreateBtn: '註冊成為廣告商',
    Benefits: '成為廣告商的好處',
    IfPublisher: "如果您是出版商，您可以在這裡註冊",
    Earning: '現在就賺錢！ ',
    Weekly: '週/月付款',
    Message1: '根據您的喜好，每週或每月自動獲得報酬。 ',
    Eff: '高效率的廣告投放',
    Message2: '最大限度地提高您的流量貨幣化，無論其地理位置如何。 ',
    CPM: 'CPM支付模型',
    Message3: '透過優化每1000次印象的收入，輕鬆增加您的收入。 ',
    Non: '非侵入性體驗',
    Message4: "不要透過設計拙劣的廣告來趕走您的使用者。我們的橫幅保證了非侵入性的使用者體驗。",
    Payout: '多種付款選項',
    Message5: '透過BTC、ETH、USDT（ERC20）或銀行電匯（SEPA/SWIFT）免費提取您的資金。 ',
    Reach: "探索新視野",
    Contact: '聯絡我們',
    Salas: '銷售團隊',
    Send: '給我們留言',
    Impressions: '印象',
    Clicks: '點閱數',
    Websites: '網站',
    Permonth: '每月',
    dataSetup:'在幾分鐘內設定你的展示廣告活動',
    dataSetupsub:'提供金融和加密廣告',
    dataSetuptext:'建立您的廣告活動，並在數百個行業網站上向正確的受眾提供加密廣告',
    dataSetuptext1:'我們的追蹤和優化工具將幫助您改進您的廣告活動，您的Coinzilla活動經理將確保您的加密廣告從頭到尾順利運行',
    dataSetup2:'被加密市場超過50%的廣告商選擇',
    dataSetuptext2:'我們主要關注的是性能。 我們與頂級金融和加密貨幣網站簽訂獨家合同，以幫助您的廣告達到正確的受眾',
    dataSetuptext3:'在網站最顯眼的地方放置橫幅可以保證你的廣告活動產生最佳效果',
  },
  publishers: {
    Earn: '提高您網站的收益',
    Across1: '我們的工具和解決方案旨在提高您的收入，同時維護無中斷的使用者體驗。 ',
    Across2: '我們的平台使您能夠創建各種廣告格式，如本地廣告和橫幅廣告，讓您輕鬆地在網站上展示它們',
    Chosen: "別猶豫，現在就加入我們吧",
    MainFocus: 'ADX是最廣泛的金融和加密貨幣廣告網絡之一，不斷擴大其出版商群體。 ',
    TheBanner: '利用我們的廣告投放系統，輕鬆提高您網站的收入，讓您實現收入最大化',
    CreateBtn: '註冊成為出版商',
    Let: '允許廣告商競爭您的廣告空間'
  },
  marketplace: {
    Buy: '購買加密貨幣新聞稿',
    Artic: '和贊助文章',
    Your: '滿足您所有內容需求的市場',
    Across1: '在加密貨幣和金融行業的知名網站上分發您的內容，以獲得您所需的可見性',
    Across2: '我們的合作夥伴網站使您能夠在財務限制範圍內制定有效的公關策略',
    GetStrat: '學習更多!',
    Let: '允許專業人員生成',
    Content: '為您預備的內容',
    Pu: '將您的新聞稿出版速度提高70%',
    Across3: '我們的團隊將根據您的要求製作獨特的新聞稿，並在我們的加密合作夥伴網站網路中分發',
    Across4: '新聞稿將迅速發布，為您省去後續出版商跟進的需要',
    Across5: '如果出版商選擇不發行您的新聞稿，不要擔心。 您將獲得全額退款，沒有任何例外',
    Across6: '一旦您的新聞稿上線，我們的團隊將立即向您提供交付證明，讓您能夠監控您的活動進展',
    We: '我們監督您的加密新聞稿的分發',
    Will: "我們將在整個過程中提供持續更新",
    Deliver: '輕鬆發布加密貨幣新聞稿',
    Submit: '在幾分鐘內提交訂單',
    Across7: '下訂單，完成一次付款，並訪問您喜歡的加密網站和博客',
    Across8: '透過一個統一的介面高效處理所有新聞稿，節省寶貴的時間和精力',
    Increased: '增強的交易安全性',
    Coinzilla: 'ADX提供託管服務',
    Across9: '您可以收到關於新聞稿傳播的即時通知，使您能夠在需要時評估自己的影響力。 另外，請放心，沒有必要擔心潛在的騙局',
    Across10: '我們的安全系統保護買家和賣家，消除任何退款風險',
    Across11: '由於我們廣泛的加密貨幣部落格和網站網絡，我們可以傳播各種主題的新聞稿',
    Across12: '此外，您可以選擇提前接受您的電子報的網站進行分類和篩選',
    Large: '種類繁多',
    Gam: '包括賭博、交易所、電競等',
  },
  coinzillae: {
    Boost: '提升您的專案',
    Boost2: '擁有頂級加密廣告網路',
    Boost3: '依靠加密貨幣廣告網路獲得專案成功',
    Ready: '我們卓越的加密貨幣廣告網絡將推動您在金融科技領域達到新的高度',
    Ready2: '',
    Launch: '啟動您的活動',
    On: '在頂級金融和加密貨幣網站上做廣告',
    Pu: '成功執行了16000多項活動',
    Across1: '有效定位您的品牌，以達到理想受眾',
    Across2: '我們與金融和加密貨幣領域的知名網站建立了強有力的合作夥伴關係。 因此，您將可以獲得獨家交易和高級廣告植入，從而將您與競爭對手區分開來',
    Across3: '調查廣泛的廣告維度和佈局，以確定最適合您的行銷計劃。 設計引人入勝的加密貨幣廣告，精確定位您想要的受眾，並透過利用即時業績數據有效控制您的支出',
    Check: '探索我們的廣告格式',
    Customize: '根據您的需求量身定制廣告和財務計劃',
    Take: '全面掌控您的計劃',
    Across4: '我們的追蹤和優化工具將幫助您提高行銷效果，您的活動經理將確保您的廣告從頭到尾順利運行',
    Chosen: '超過65%的加密貨幣廣告商選擇',
    Result: '成果驅動型促銷',
    Self: '自我管理的活動',
    Message1: '輕鬆監督和自訂您的展示活動',
    Cam: '增強的市場活動績效',
    Message2: '在維持成本效益的同時達成廣告目標',
    Html: '自訂HTML5橫幅廣告',
    Message3: '透過個人化的HTML5橫幅與觀眾互動',
    Flex: '適應性支出計劃',
    Message4: '根據支出和結果對廣告分發進行微調',
    Str: '策略定位廣告',
    Message5: '放置橫幅以獲得最大的可見性',
    Your: '廣告商的好處',
    If: "您是出版商嗎？立即註冊！",
    Adver: '今日廣告',
    Say: '來自客戶的回饋',
    Setup: '在幾分鐘內設定您的加密貨幣廣告活動',
    Reach: '吸引數百萬新客戶',
    Across5: '我們的追蹤和優化工具將幫助您提高行銷效果，而您的活動經理將確保您的廣告從開始到結束順利運行。 ',
    tagMessage1: '我們很高興能與ADX的傑出團隊合作！ 他們表現出非凡的專業精神和守時精神，始終如一地取得優異成績。 他們的服務極大地提高了加密項目所需的曝光率，他們一直在努力實現卓越的工作',
    tagMessage2: '得益於我們在ADX上管理廣告活動的豐富經驗和寶貴見解，Student Coin成功地與大量全球投資者建立了聯繫。 在我們成立的短短12個月內，我們自豪地躋身全球市值前300的加密貨幣之列',
    tagMessage3: 'Student Coin在透過ADX管理廣告活動方面擁有豐富的專業知識和支持，成功地建立了龐大的全球投資者基礎。 在我們成立的短短12個月內，我們就躋身市值前300的加密貨幣之列.',
    title1: "A.華爾街遊戲執行長",
    title2: "Daniel 首席行銷長",
    title3: "Daniel 首席行銷長",
    data1:"成為我們成功的廣告商之一。依靠我們與領先的金融和加密貨幣平台的獨特合作，以及專業建議和定制協助，您的營銷計劃將迅速產生非凡的成果",
  },
  foot: {
    abouttitle: '關於我們',
    about1: '關於我們',
    about2: '認識我們的合作夥伴',
    about3: '聯絡我們',
    SERVICEStitle: '服務',
    services1: '廣告商的ADX',
    services2: '出版商的ADX',
    services3: 'ADX市場',
    HELPtitle: '幫助',
    help1: '部落格',
    help2: '常見問題',
    help3: '下載媒體工具包',
    help4: '我們的廣告格式',
    SOCIALPRESENCEtitle: '社交合作',
    social1: '臉書',
    social2: '推特',
    social3: 'Youtube',
    social4: '領英',
  },
  contact: {
    sendTitle: '給我們留言，讓我們開始吧！ ',
    sendMessage1: '如果您準備好開始合作，請告訴我們。 使用我們的聯絡表單向我們發送訊息，我們會盡快回覆您。 ',
    sendMessage2: '如果您認為您的隱私或保密性遭到我們的發布商或廣告商之一的侵犯，或者如果您目睹了違反我們條款和條件的行為，我們強烈建議您通過我們的報告濫用工具報告 濫用行為。 ',
    sendTitle2: '報告濫用工具',
    contactTeam: '聯絡我們的團隊',
    reportAbuse: '報告濫用',
    form1title1: '帳號類型',
    form1input1: "我是廣告主",
    form1input12: "我是出版商",
    form1title2: '姓名',
    form1input2: "您的全名",
    form1title3: '電子郵件地址',
    form1input3: "您的電子郵件地址",
    form1title4: '即時通訊',
    form1input4: "Skype、電報或電話號碼（可選）",
    form1title5: '訊息',
    form1input5: "在這裡寫下您的問題或詢問...",
    submit: '提交您的留言',
    form2title1: '報告類型',
    form2input1: "出版商/網站",
    form2input12: "廣告主/登陸頁 URL",
    form2input13: "橫幅廣告",
    form2input14: "違反資料保護規定",
    form2title2: '違規網站/頁面 URL',
    form2title3: '截圖/證明網址',
    form2input3: "https://prnt.sc/...（選購）",
    form2title4: '訊息',
    form2input4: "在此寫下您的訊息，以幫助我們更了解報告類型...",
    AvailableSizes: '可用尺寸:',
    Highlights: '強調',
  },
  //常见问题
  fag: {
    General: '普遍',
    Advertisers: '廣告商',
    Publishers: '出版商',
    //General
    Help: '我們如何幫助您？ ',
    SearchAnswer: '搜尋問題',
    ChangeEmail: '可以更改我的郵件地址嗎？ ',
    EmailAnswer: '您只能透過您帳戶目前使用的電子郵件地址向我們的團隊提出請求來更改您的電子郵件地址。 ',
    ChangPassword: '怎麼修改我的密碼？ ',
    Password1: '您可以透過前往儀表板中設定標籤下的',
    changPassword: '更改密碼',
    Password2: '部分來更改密碼。 ',
    HackedAccount: '我認為我的帳號被駭了怎麼辦？ ',
    HackedAnswer: '如果您有理由相信您的帳戶已被盜用，請立即更改密碼並與您的客戶經理聯繫。 ',
    HaveAccounts: '我可以有多個帳號嗎?',
    HaveAccountsAnswer: '不可以，不允許使用多個帳號。 ',
    //Advertisers
    BecomeAdvertiser: '我如何成為廣告商？ ',
    BecomeAdvertiserAnswer: '為了成為廣告商，您必須註冊一個帳戶。 然後我們會聯絡您討論您活動的所有細節。 ',
    MakeDeposit: '我該如何存款？ ',
    MakeDepositAnswer: '您可以透過存款資金工具在儀表板的帳單部分進行存款。 ',
    ProvideSupport: '您是否為設定活動提供支援？ ',
    ProvideSupportAnswer: '是的，我們的團隊將在整個活動創建過程中為您提供協助。 這從建議您如何優化您的活動，到設計您的橫幅以獲得最佳效果。 ',
    ProvideAssistance: '您是否提供橫幅設計的協助？ ',
    ProvideAssistanceAnswer: '是的。 我們的設計師已準備好根據您的喜好創建您的橫幅。 ',
    AdFormats: '有哪些可用的廣告格式？ ',
    AdFormatsAnswer: '一些可用的廣告格式包括經典橫幅、標題橫幅、黏性橫幅和原生廣告。 您可以查看我們完整的廣告格式清單。 ',
    AdFormatsAnswer2: '在這裡。 ',
    Difference: "品牌知名度與優質流量有什麼不同？",
    DifferenceAnswer: "高級套餐提供來自加密利基市場的高級網站的流量。這也反映在兩個套餐的CPM中，品牌知名度流量的CPM為0.2美元。",
    SpecificCountries: '我可以針對特定國家嗎？ ',
    SpecificCountriesAnswer: '是的，您可以根據國家或裝置定位您的受眾。 ',
    GoLive: '我的活動要多久才能上線？ ',
    GoLiveAnswer: '提交後，您的廣告活動將在1個工作天內上線。 但是，在週末或假期，您的廣告活動可能需要不到48小時才能上線。 ',
    GetRefund: '我要怎麼退款？ ',
    GetRefundAnswer: '有關我們退款政策的信息，請查看我們的 ',
    GetRefundAnswer2: '條款與條件。 ',
    //Publishers
    Pulish1: '我如何成為出版商？ ',
    Pulish2: '我可以增加幾個網站？ ',
    Pulish3: '什麼樣的網站被接受？ ',
    Pulish4: '您們的費率是多少？ ',
    Pulish5: '提款的費用是多少？ ',
    Pulish6: '我會根據點擊次數或展示次數付費嗎？ ',
    Pulish7: '我可以請我的用戶點擊廣告嗎？ ',
    Pulish8: '為什麼我在儀表板上獲得的展示次數少於其他網路上的展示次數？ ',

    Pulish1Mess: '為了成為發布商，您必須註冊一個帳戶並透過發布商儀表板提交您的網站。 一旦獲得批准，我們將開始在您的網站上顯示橫幅。 ',
    Pulish2Mess: '您可以投放廣告的網站數量沒有限制。 ',
    Pulish3Mess: '作為一個金融和加密貨幣廣告網絡，ADX 只接受與加密貨幣相關的網站和項目加入其網絡。 ',
    Pulish4Mess: '費率依網站的品質而定。 在過程中，我們會考慮各種因素，例如網站設計、內容品質等。 ',
    Pulish5Mess: '沒有提款費用。 ',
    Pulish6Mess: '我們為橫幅廣告和原生廣告製定每千次曝光費用 (CPM) 支付模式。 ',
    Pulish7Mess: '不可以。 用戶只有在對廣告感興趣時才應該與廣告互動。 要求您的用戶點擊廣告將導致廣告主為低品質的流量付費，並會導致您被禁止進入我們的網路。 ',
    Pulish8Mess: '作為發布商，每次用戶查看您網站上放置的橫幅時，您都會賺錢。 這意味著，如果橫幅放置在頁面底部並且訪客沒有滾動那麼遠，則該視圖將不會計入。 實施此措施是為了確保廣告商僅在用戶與或互動時付費查看他們的廣告。 ',

  },
  formats: {
    title: '我們的廣告格式',
    DisplayCampaign: '展示活動',
    subtitlea: '我們的經典橫幅提供了一種吸引目標受眾注意力的簡單方法',
    Displayfeaturea: '較高的知名度',
    Displayfeatureb: '接受HTML5橫幅',
    Displayfeaturec: 'CPM付費模式',
    Displayfeatured: '平均0.25% - 0.30%點擊率',
    CreateCampaign: '創建行銷活動',
    PopupAd: '穿插廣告',
    subtitleb: '在網頁載入間隙展示的全螢幕廣告',
    Nativefeaturea: '很棒的位置',
    Nativefeatureb: '可透過公用API進行客製化',
    Nativefeaturec: 'CPM付費模式',
    Anchoradvertising: '錨定廣告',
    subtitlec: '固定在頁面頂部或底部邊緣的廣告',
    Popunderfeaturea: '快速顯示',
    Popunderfeatureb: '單一選擇加入優惠的理想選擇',
    Popunderfeaturec: 'CPM付費模式',
    Popunderfeatured: '平均0.25% - 0.30%點擊率',
    DisplayCampaignMessage: '展示活動說明',
    PopupAdMessage: '穿插廣告說明',
    AnchoradvertisingMessage: '錨定廣告說明',
    AvailableSizes: '可用尺寸:',
    Highlights: '強調',
  }
}
export default zhTW