
const en = {
  // 导航栏
  nav: {
    Advertisers: 'Advertisers',
    Publishers: 'Publishers',
    Marketplace: 'Marketplace',
    Blog: 'Blog',
    SignUp: 'Sign Up',
    SignIn: 'Sign In',
    Lang: "Language",
    Lang1: 'English',
    Lang2: "中文(简体)",
    Lang3: "Japanese",
    Lang4: "español",
    Langfr: "Français",
    Langzhtw: "中文(繁體)",
  },
  advertisers: {
    Setup: 'Effortlessly establish',
    Min: 'your display ad campaign with minimal steps.',
    Deliver: 'Serves financial and cryptocurrency advertising',
    SeeBtn: 'See our ad formats',
    Across1: 'Develop your advertising strategy, showcase your cryptocurrency ads to the right target audience,  and connect with numerous industry websites.',
    Across2: 'By utilizing our tracking and optimization tools, you can swiftly enhance your marketing effectiveness, while the campaign manager will ensure the smooth operation of your advertisements.',
    Chosen: 'Selected by a majority of advertisers within the cryptocurrency market.',
    MainFocus: 'Focusing on performance, advertising collaborates with premier financial and cryptocurrency websites to ensure your ads effectively target the appropriate audience.',
    TheBanner: "To yield the best results from your marketing campaigns, it's essential to position your banners in the most prominent sections of your website.",
    CreateBtn: 'Register to become an advertiser',
    Benefits: 'Benefits of becoming an advertiser',
    IfPublisher: "If you're a publisher, you can sign up here ",
    Earning: 'Making Money Now!',
    Weekly: 'Weekly/Monthly Payments',
    Message1: 'Get paid automatically weekly or monthly, based on your preferences.',
    Eff: 'Efficient Ad Delivery',
    Message2: 'Maximize your traffic monetization, regardless of its geographic location.',
    CPM: 'CPM Payment Model',
    Message3: 'Easily increase your earnings by optimizing your Revenue per 1000 Impressions.',
    Non: 'Non-Intrusive Experience',
    Message4: "Don't drive your users away through poorly designed ads. Our banners guarantee a non-intrusive user experience.",
    Payout: 'Multiple Payout Options',
    Message5: 'Withdraw your funds with no costs via BTC,ETH, USDT (ERC20), or Bank Wire (SEPA/SWIFT).',
    Reach: "Explore New Horizons.",
    Contact: 'Contact Our',
    Salas: 'Sales Team',
    Send: ' Send us a message',
    Impressions: 'Impressions',
    Clicks: 'Clicks',
    Websites: 'Websites',
    Permonth: ' per month',
    dataSetup:'Set up your display advertising campaign within minutes',
    dataSetupsub:'DELIVER FINANCE & CRYPTO ADS',
    dataSetuptext:'Set up your advertising campaign and deliver crypto ads to the right audience across hundreds of industry websites.',
    dataSetuptext1:'Our tracking and optimization tools will help you improve your ad campaigns, and your Coinzilla campaign manager will make sure your crypto ads run smoothly from start to finish.',
    dataSetup2:'Chosen by over 50% of advertisers in the crypto marke',
    dataSetuptext2:'Our main focus is performance. We sign exclusive contracts with top finance & cryptocurrency websites to help your ads reach the right audience.',
    dataSetuptext3:'The banner placement in the most visible areas of a website guarantees that your campaign will generate optimum results.',
  },
  publishers: {
    Earn: "Boost your website's earnings",
    Across1: 'Our tools and solutions are crafted to enhance your income while upholding a user experience that is non-disruptive.',
    Across2: 'Our platform enables you to create various ad formats like native ads and banner ads, making it effortless for you to showcase them on your website.',
    Chosen: "Don't hesitate, join us now！",
    MainFocus: 'ADX ranks among the most extensive financial and cryptocurrency advertising networks, continually expanding its pool of publishers.',
    TheBanner: "Leverage our advertising delivery system to enhance your website's revenue effortlessly, allowing you to maximize your earnings.",
    CreateBtn: 'Sign up to become a publisher',
    Let: 'Allow advertisers to compete for your advertising space.'
  },
  marketplace: {
    Buy: 'Purchase Cryptocurrency Press Releases',
    Artic: 'and Sponsored Articles.',
    Your: 'Marketplace for all your content needs',
    Across1: 'Distribute your content across prominent websites within the crypto and finance sectors to attain the visibility you require.',
    Across2: 'Our partner websites enable you to establish an efficient PR strategy that operates within your financial constraints.',
    GetStrat: 'Learn More',
    Let: 'Allow Professionals to Generate',
    Content: 'Content for You',
    Pu: 'EXPEDITE THE PUBLICATION OF YOUR PRESS RELEASE BY AS MUCH AS 70%',
    Across3: 'Our team will craft distinctive press releases tailored to your requirements and distribute them across our network of crypto partner websites.',
    Across4: 'The press releases will be swiftly published, saving you the need for subsequent publisher follow-ups.',
    Across5: "If a publisher ever chooses not to distribute your press release, don't fret. You will receive a complete refund without any exceptions.",
    Across6: "Once your press release goes live, our team will furnish you with proof of delivery promptly, allowing you to monitor your campaign's progress.",
    We: 'We Oversee the Distribution of Your Crypto Press Release',
    Will: "We'll Provide Ongoing Updates Throughout the Procedure.",
    Deliver: 'Deliver Your Crypto Press Releases Effortlessly',
    Submit: 'SUBMIT YOUR ORDER IN MINUTES',
    Across7: 'Place your order, complete a single payment, and access the crypto websites and blogs you prefer.',
    Across8: 'Efficiently handle all your press releases through one unified interface, saving valuable time and effort.',
    Increased: 'Enhanced Transaction Safety',
    Coinzilla: 'ADX OFFERS ESCROW SERVICES',
    Across9: "You can receive real-time notifications on the dissemination of your press releases, enabling you to assess your influence whenever needed. Plus, rest assured, there's no need to concern yourself with potential scams.",
    Across10: 'Our security system safeguards both buyers and sellers, eradicating any chargeback risk.',
    Across11: 'Due to our extensive network of cryptocurrency blogs and websites, we can disseminate press releases on various subjects.',
    Across12: 'Additionally, you have the option to categorize and filter websites that accept your newsletter in advance.',
    Large: 'A Wide Range of Categories',
    Gam: 'Including Gambling, Exchanges, Esports, and More',
  },
  coinzillae: {
    Boost: 'Boost your project',
    Boost2: 'with the top crypto advertising network',
    Boost3: 'Relying on Cryptocurrency Advertising Networks for Project Success',
    Ready: 'Our exceptional cryptocurrency advertising network will propel you to new heights in the fintech sector.',
    Ready2: '',
    Launch: 'Launch your campaign',
    On: 'Advertise on top finance & crypto websites',
    Pu: 'Over 16,000 Campaigns Successfully Executed',
    Across1: 'Position your brand effectively to reach the ideal audience.',
    Across2: "We establish strong partnerships with reputable websites in the finance and cryptocurrency sector. As a result, you'll have access to exclusive deals and premium advertising placements that will distinguish you from your competitors.",
    Across3: 'Investigate an extensive array of ad dimensions and layouts to identify the ideal fit for your marketing initiative. Design captivating cryptocurrency advertisements, pinpoint your desired audience, and efficiently control your expenditures by leveraging live performance data.',
    Check: 'Explore our advertising formats.',
    Customize: 'Tailor your advertisements and financial plans to suit your needs.',
    Take: 'Seize Total Command Over Your Initiatives',
    Across4: "Our tracking and optimization tools will help you improve your marketing results, and your campaign manager will make sure your ads run smoothly from start to finish.",
    Chosen: 'Chosen by over 65% of crypto advertisers',
    Result: 'Outcome-Driven Promotion',
    Self: 'Self-Administered Campaigns',
    Message1: 'Effortlessly oversee and tailor your display campaigns.',
    Cam: 'Enhanced Campaign Performance',
    Message2: 'Attain your advertising objectives while staying cost-effective.',
    Html: 'Custom HTML5 Banner Ads',
    Message3: 'Interact with your audience through personalized HTML5 banners.',
    Flex: 'Adaptable Spending Plans',
    Message4: 'Fine-tune your ad distribution according to expenses and results.',
    Str: 'Strategically Positioned Ads',
    Message5: 'Position your banners for maximum visibility.',
    Your: 'Benefits for Advertisers',
    If: "Are you a publisher? Register Now!",
    Adver: 'Advertising Today',
    Say: 'Feedback from our clients',
    tagMessage1: 'We are thrilled to collaborate with the exceptional team at ADX! They demonstrate remarkable professionalism and punctuality, consistently delivering exceptional results. Their services greatly enhance the exposure needed for crypto projects, and they consistently strive for excellence in their work.',
    tagMessage2: 'Thanks to our extensive experience and invaluable insights gained from managing advertising campaigns on ADX, Student Coin successfully engaged with a substantial global investor base. Within a mere twelve months of our establishment, we proudly achieved a position among the top 300 cryptocurrencies worldwide by market capitalization.',
    tagMessage3: 'Having substantial expertise and support in managing advertising campaigns through ADX, Student Coin successfully reached a substantial global investor base. Within just twelve months of our inception, we achieved a position among the top 300 cryptocurrencies by market capitalization.',
    Setup: 'Establish your advertising campaign for cryptocurrencies effortlessly.',
    Reach: 'Reach a Vast Audience',
    Across5: 'By utilizing our tracking and optimization tools, you can swiftly enhance your marketing effectiveness, while the campaign manager will ensure the smooth operation of your advertisements.',
    title1: "A. Wall Street Games' CEO",
    title2: "Daniel CMO {'@'}StudentCoin",
    title3: "Daniel CMO {'@'}StudentCoin",
    data1:"Become one of our successful advertisers. Depend on our unique collaborations with leading financial and cryptocurrency platforms, and with professional advice and customized assistance, your marketing initiatives will swiftly yield exceptional outcomes.",
  },
  foot: {
    abouttitle: 'ABOUT',
    about1: 'Who Are We?',
    about2: 'Meet our Partners',
    about3: 'Contact Us',
    SERVICEStitle: 'SERVICES',
    services1: 'ADX for Advertisers',
    services2: 'ADX for Publishers',
    services3: 'ADX Marketplace',
    HELPtitle: 'HELP',
    help1: 'Blog',
    help2: 'Frequently Asked Questions',
    help3: 'Download Media Kit',
    help4: 'Our Ad Formats',
    SOCIALPRESENCEtitle: 'SOCIAL PRESENCE',
    social1: 'Facebook',
    social2: 'Twitter',
    social3: 'Youtube',
    social4: 'Linkedin',
  },
  contact: {
    sendTitle: 'Send us a message and let’s get started!',
    sendMessage1: 'Let us know if you’re ready to start working together. Send us a message using our contact form, and we’ll get back to you as soon as possible.',
    sendMessage2: 'If you ever feel like your privacy or confidentiality were violated by one of our publishers or advertisers, or if you have witnessed a breach of our terms and conditions, we strongly encourage you to report the abuse via our Report Abuse Tool.',
    sendTitle2: 'Report Abuse Tool',
    contactTeam: 'Contact Our Team',
    reportAbuse: 'Report Abuse',
    form1title1: 'Account Type',
    form1input1: "I'm an Advertiser",
    form1input12: "I'm a Publisher",
    form1title2: 'Name',
    form1input2: "Your full name",
    form1title3: 'Email Address',
    form1input3: "Your email address",
    form1title4: 'Instant Messenger',
    form1input4: "Skype, telegram or phone number ( optional )",
    form1title5: 'Message',
    form1input5: "Write here your questions or inquiries...",
    submit: 'Submit Your Message',
    form2title1: 'Report Type',
    form2input1: "Publisher / Website",
    form2input12: "Advertiser / Landing Page URL",
    form2input13: "Banner Ads",
    form2input14: "Data Protection Violation",
    form2title2: 'Violation Website/Page URL',
    form2title3: 'Screenshot / Proof URL',
    form2input3: "https://prnt.sc/.... (optional)",
    form2title4: 'Message',
    form2input4: "Write here your message to help us better understand the report type...",
  },
  //常见问题
  fag: {
    General: 'General',
    Advertisers: 'Advertisers',
    Publishers: 'Publishers',
    //General
    Help: 'How may we help you?',
    SearchAnswer: 'Search for answers',
    ChangeEmail: 'Can I change my email  address？',
    EmailAnswer: 'Your e-mail address can be changed only by making a request to our team from the e-mail address that is currently used for your account.',
    ChangPassword: 'How can I change my  password?',
    Password1: 'You can change your password by going to the',
    changPassword: 'Change Password ',
    Password2: 'section in your dashboard, under the Settings tab.',
    HackedAccount: 'I think my account was hacked. What should I do?',
    HackedAnswer: 'If you have reason to believe that your account was compromised, change your password immediately and get in touch with your account manager.',
    HaveAccounts: 'Can I have multiple accounts?',
    HaveAccountsAnswer: 'No, multiple accounts are not allowed.',
    //Advertisers
    BecomeAdvertiser: 'How do I become an advertiser?',
    BecomeAdvertiserAnswer: 'In order to become an advertiser, you’ll have to sign up for an account. We will then contact you to discuss all the details of your campaign.',
    MakeDeposit: 'How can I make a deposit?',
    MakeDepositAnswer: 'You can make deposits in the billing section of your dashboard, through the Deposit Funds tool.',
    ProvideSupport: 'Do you provide support in setting up campaigns?',
    ProvideSupportAnswer: 'Yes, our team will assist you throughout the campaign creation process. This goes from advising you on how to optimize your campaign, to designing your banners for optimum results.',
    ProvideAssistance: 'Do you provide assistance with banner design?',
    ProvideAssistanceAnswer: 'Yes. Our designers are ready to create your banners according to your preferences.',
    AdFormats: 'What are the available ad formats?',
    AdFormatsAnswer: 'Some of the ad formats available include Classic Banners, Header Banners, Sticky Banners, and Native Ads. You can check our full list of ad formats.',
    AdFormatsAnswer2: 'here',
    Difference: "What's the difference between brand awareness and premium traffic?",
    DifferenceAnswer: "The premium package provides traffic from premium websites from the crypto niche. This is also reflected in the CPM for both packages, with the brand awareness traffic having a CPM of $0.2 CPM Premium one of $3.",
    SpecificCountries: 'Can I target specific countries?',
    SpecificCountriesAnswer: 'Yes, you can target your audience based on country or device.',
    GoLive: 'How long will it take for my campaign to go live?',
    GoLiveAnswer: 'After submission, your campaign will go live within 1 business day. However, during weekends or holidays, it may take up to 48 hours for your campaign to go live.',
    GetRefund: 'How can I get a refund?',
    GetRefundAnswer: 'For information about our refund policy, please check our ',
    GetRefundAnswer2: 'Terms and Conditions.',
    //Publishers
    Pulish1: 'How do I become a publisher?',
    Pulish2: 'How many websites can I add?',
    Pulish3: 'What kind of websites are accepted?',
    Pulish4: 'What are your rates?',
    Pulish5: 'What are the fees for withdrawing?',
    Pulish6: 'Will I be paid for clicks or impressions?',
    Pulish7: 'Can I ask my users to click on ads?',
    Pulish8: 'Why do I get fewer impressions on my dashboard than on other networks?',

    Pulish1Mess: 'In order to become a publisher, you’ll have to sign up for an account and submit your website through your publisher dashboard. Once approved, we will start displaying banners on your website.',
    Pulish2Mess: 'There is no limit on the number of websites you can deliver ads on.',
    Pulish3Mess: 'Being a finance & crypto advertising network, ADX is only accepting cryptocurrency-related websites and projects to join its network. Please see all our criteria for eligibility on our Website Acceptance Policy section.',
    Pulish4Mess: 'The rates are set depending on the quality of a website. In this process, we look at various factors such as website design, content quality, and more.',
    Pulish5Mess: 'There are no withdrawal fees.',
    Pulish6Mess: 'We work on a CPM payment model for both banner and native ads.',
    Pulish7Mess: 'No. Users should interact with an ad only if they are interested in it. Asking your users to click on ads will result in advertisers paying for low-quality traffic, and will get you banned from our network.',
    Pulish8Mess: 'As a publisher, you earn money each time a user views a banner placed on your website. This means that if a banner is placed on the bottom of a page and a visitor doesn’t scroll that far, the view won’t count.This measure was implemented in order to make sure that advertisers pay only when a user interacts with or views their ad.',
  },
  formats: {
    title: 'Our Ad Formats',
    CreateaCampaigns: 'Create a Campaigns',
    DisplayCampaign: 'Display Campaign',
    subtitlea: 'Our classic banners provide an easy way to catch the attention of your target audience.',
    Displayfeaturea: 'High visibility',
    Displayfeatureb: 'HTML5 banners accepted',
    Displayfeaturec: 'CPM payment model',
    Displayfeatured: '0.25% - 0.30% avg. CTR',
    CreateCampaign: 'Create Campaign',
    PopupAd: 'Pop-up Ad',
    subtitleb: 'Full screen ads displayed during webpage loading gaps',
    Nativefeaturea: 'Great placement',
    Nativefeatureb: 'HTML5 banners accepted',
    Nativefeaturec: 'Customizable via the public API',
    Anchoradvertising: 'Anchor Advertising',
    subtitlec: 'Advertising fixed at the top or bottom edge of the page',
    Popunderfeaturea: 'Quick display with high visibility',
    Popunderfeatureb: 'Ideal for single opt-in offers',
    Popunderfeaturec: 'CPM payment model',
    Popunderfeatured: '0.25% - 0.30% avg. CTR',
    DisplayCampaignMessage: 'Display Activity Description',
    PopupAdMessage: 'Pop-up Ad Description',
    AnchoradvertisingMessage: 'Anchor Ad Description',
    AvailableSizes: 'Available Sizes',
    Highlights: 'Highlights',
  }
}
export default en