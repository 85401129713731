export default ({
    namespaced: true,
    state: {
        //国际化语言监听
        value: localStorage.getItem('language')
    },
    getters: {
    },
    mutations: {
        langChoose(state: any, val: string) {
            state.value = val
            localStorage.setItem('language', val)
        }
    },
    actions: {
    },
})