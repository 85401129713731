import { createI18n } from 'vue-i18n';
import en from '@/i18n/config/en';
import zhCN from '@/i18n/config/zh-cn';
import es from '@/i18n/config/es';
import fr from '@/i18n/config/fr';
import zhTW from '@/i18n/config/zh-tw';

// 获取浏览器的语言
const lang = (navigator.language || 'en').toLocaleLowerCase()
let language = localStorage.getItem('language') || lang.split('-')[0] || 'en';
const languagearr = ['zhCN', 'en', 'es', 'fr','zhTW'];//已有语言列表
if (!languagearr.includes(language)) {//其他语言默认显示简体中文
  language = 'en'
}
localStorage.setItem('language', language)
console.log("language", navigator.language)

const I18n = createI18n({
  legacy: false,
  // 全局注册 $t方法
  globalInjection: true,
  //设置初始化语言
  // locale:language,
  locale: language,
  messages: {
    en,
    es,
    zhCN,
    fr,
    zhTW
  }
})
export default I18n;