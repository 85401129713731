const fr = {
  // 导航栏
  nav: {
    Advertisers: 'Annonceurs',
    Publishers: 'Éditeurs',
    Marketplace: 'Marché',
    Blog: 'blog',
    SignUp: "S'inscrire",
    SignIn: 'Connexion',
    Lang: "langue",
    Lang1: 'English',
    Lang2: "中文(简体)",
    Lang3: "日语",
    Lang4: "español",
    Langfr: "Français",
    Langzhtw: "中文(繁體)",
  },
  advertisers: {
    Setup: 'Configurez facilement votre campagne de publicité display',
    Min: 'Étapes minimales requises',
    Deliver: 'Fournit de la publicité financière et de crypto-monnaie',
    SeeBtn: "Voir nos formats d'annonces",
    Across1: 'Développez votre stratégie publicitaire pour afficher vos publicités de crypto-monnaie auprès du bon public cible et vous connecter à de nombreux sites Web du secteur.',
    Across2: "En utilisant nos outils de suivi et d'optimisation, vous pouvez rapidement améliorer vos performances marketing, tandis qu'un gestionnaire de campagne garantira le bon déroulement de vos annonces.",
    Chosen: 'Choisi par la plupart des annonceurs sur le marché des cryptomonnaies.',
    MainFocus: 'Axées sur les performances, la publicité fonctionne avec les meilleurs sites Web financiers et de crypto-monnaie pour garantir que vos publicités ciblent efficacement le bon public.',
    TheBanner: 'Pour obtenir les meilleurs résultats de votre campagne marketing, il est crucial de placer votre bannière dans la partie la plus visible de votre site Web.',
    CreateBtn: "S'inscrire en tant qu'annonceur",
    Benefits: 'Avantages de devenir annonceur',
    IfPublisher: "Si vous êtes éditeur, vous pouvez vous inscrire ici",
    Earning: "Gagnez de l'argent maintenant !",
    Weekly: 'Paiement hebdomadaire/mensuel',
    Message1: 'Soyez payé automatiquement chaque semaine ou chaque mois, selon vos préférences.',
    Eff: 'Publicité efficace',
    Message2: 'Maximisez la monétisation de votre trafic, quelle que soit la zone géographique.',
    CPM: 'Modèle de paiement CPM',
    Message3: 'Augmentez facilement vos revenus en optimisant les revenus pour 1 000 impressions.',
    Non: 'Expérience non invasive',
    Message4: "Ne faites pas fuir vos utilisateurs avec des publicités mal conçues. Nos bannières garantissent une expérience utilisateur non intrusive.",
    Payout: 'Plusieurs options de paiement',
    Message5: 'Retirez vos fonds gratuitement via BTC, ETH, USDT (ERC20) ou virement bancaire (SEPA/SWIFT).',
    Reach: "Explorer de nouveaux horizons",
    Contact: 'Contactez-nous',
    Salas: 'Équipe commerciale',
    Send: 'Laissez-nous un message',
    Impressions: 'impression',
    Clicks: 'Clics',
    Websites: 'site Internet',
    Permonth: 'Chaque mois',
    dataSetup:'Configurez votre campagne de publicité display en quelques minutes',
    dataSetupsub:'Fournit de la publicité financière et cryptographique',
    dataSetuptext:'Créez votre campagne et diffusez des publicités cryptographiques au bon public sur des centaines de sites Web du secteur',
    dataSetuptext1:"Nos outils de suivi et d'optimisation vous aideront à améliorer vos campagnes, et votre gestionnaire de campagne Coinzilla veillera à ce que vos publicités cryptographiques se déroulent sans problème du début à la fin",
    dataSetup2:'Choisi par plus de 50 % des annonceurs sur le marché de la cryptographie',
    dataSetuptext2:'Notre principale préoccupation est la performance. Nous avons des contrats exclusifs avec les meilleurs sites Web financiers et de crypto-monnaie pour aider vos annonces à atteindre le bon public',
    dataSetuptext3:'Placer des bannières aux endroits les plus visibles de votre site Web garantira les meilleurs résultats pour vos campagnes publicitaires',
  },
  publishers: {
    Earn: 'Améliorez les revenus de votre site Web',
    Across1: 'Nos outils et solutions sont conçus pour augmenter vos revenus tout en conservant une expérience utilisateur non perturbatrice.',
    Across2: 'Notre plateforme vous permet de créer une variété de formats publicitaires tels que des annonces natives et des bannières publicitaires, vous permettant de les afficher facilement sur votre site Web',
    Chosen: "N'hésitez pas, rejoignez-nous maintenant",
    MainFocus: "ADX est l'un des réseaux publicitaires financiers et de crypto-monnaie les plus vastes et continue d'élargir sa base d'éditeurs.",
    TheBanner: 'Utilisez notre système publicitaire pour augmenter facilement les revenus de votre site Web et maximiser vos revenus',
    CreateBtn: "S'inscrire en tant qu'éditeur",
    Let: 'Autoriser les annonceurs à rivaliser pour votre espace publicitaire'
  },
  marketplace: {
    Buy: "Communiqué de presse sur l'achat de crypto-monnaie",
    Artic: 'et articles sponsorisés',
    Your: 'Une place de marché pour tous vos besoins en contenu',
    Across1: 'Distribuez votre contenu sur des sites Web réputés dans le secteur des cryptomonnaies et de la finance pour obtenir la visibilité dont vous avez besoin',
    Across2: 'Nos sites partenaires vous permettent de développer une stratégie RP efficace dans le respect des contraintes financières',
    GetStrat: 'En savoir plus !',
    Let: 'Permettre aux professionnels de générer',
    Content: 'Contenu préparé pour vous',
    Pu: 'Publiez vos communiqués de presse 70% plus vite',
    Across3: 'Notre équipe créera un communiqué de presse unique basé sur votre demande et le diffusera sur notre réseau de sites Web partenaires crypto',
    Across4: "Les communiqués de presse seront publiés rapidement, ce qui vous évitera un suivi ultérieur de la part de l'éditeur",
    Across5: 'Ne vous inquiétez pas si un éditeur choisit de ne pas publier votre communiqué de presse. Vous recevrez un remboursement complet, sans exception',
    Across6: "Dès la mise en ligne de votre communiqué, notre équipe vous fournira un justificatif de livraison, vous permettant de suivre l'avancée de votre campagne",
    We: 'Nous surveillons la diffusion de vos communiqués crypto',
    Will: "Nous fournirons des mises à jour continues tout au long de ce processus",
    Deliver: 'Publiez facilement des communiqués de presse sur les cryptomonnaies',
    Submit: 'Soumettre la commande dans quelques minutes',
    Across7: 'Passez votre commande, payez une fois et accédez à vos sites et blogs cryptographiques préférés',
    Across8: 'Traitez efficacement tous les communiqués de presse via une interface unifiée, économisant ainsi un temps et une énergie précieux',
    Increased: 'Sécurité renforcée des transactions',
    Coinzilla: 'ADX fournit des services de garde',
    Across9: "Vous pouvez recevoir des notifications en temps réel de la diffusion d'un communiqué de presse, vous permettant ainsi d'évaluer votre impact en cas de besoin. Soyez également assuré que vous n'avez pas à vous soucier des escroqueries potentielles.",
    Across10: 'Notre système de sécurité protège les acheteurs et les vendeurs, éliminant tout risque de rétrofacturation',
    Across11: 'Grâce à notre vaste réseau de blogs et de sites Web sur les cryptomonnaies, nous pouvons diffuser des communiqués de presse sur une variété de sujets',
    Across12: "De plus, vous avez la possibilité de trier et filtrer à l'avance les sites Internet qui acceptent votre newsletter",
    Large: 'diverses sortes',
    Gam: 'Y compris les jeux de hasard, les échanges, les sports électroniques, etc.',
  },
  coinzillae: {
    Boost: 'Boostez votre projet',
    Boost2: 'Possède un réseau publicitaire crypté de premier plan',
    Boost3: 'Comptez sur le réseau publicitaire de crypto-monnaie pour la réussite du projet',
    Ready: 'Notre réseau publicitaire supérieur de crypto-monnaie vous propulsera vers de nouveaux sommets en matière de fintech',
    Ready2: '',
    Launch: 'Lancez votre activité',
    On: 'Faites de la publicité sur les meilleurs sites Web financiers et de crypto-monnaie',
    Pu: 'Plus de 16 000 campagnes exécutées avec succès',
    Across1: 'Positionnez efficacement votre marque pour atteindre votre public idéal',
    Across2: 'Nous avons des partenariats solides avec des sites Web réputés dans le domaine financier et des crypto-monnaies. En conséquence, vous aurez accès à des offres exclusives et à des emplacements publicitaires premium qui vous différencieront de ceux de vos concurrents',
    Across3: "Étudiez un large éventail de dimensions et de mises en page d'annonces pour déterminer le meilleur plan marketing pour vous. Concevez des publicités attrayantes sur les crypto-monnaies, identifiez votre public souhaité et contrôlez efficacement vos dépenses en exploitant les données de performances en temps réel",
    Check: "Explorez nos formats d'annonces",
    Customize: 'Personnalisez les plans publicitaires et financiers en fonction de vos besoins',
    Take: 'Contrôle total de vos projets',
    Across4: "Nos outils de suivi et d'optimisation vous aideront à améliorer votre efficacité marketing, et votre gestionnaire de campagne veillera au bon déroulement de vos publicités du début à la fin",
    Chosen: 'Choisi par plus de 65 % des annonceurs de crypto-monnaie',
    Result: 'Promotion axée sur les résultats',
    Self: 'Activités autogérées',
    Message1: 'Surveillez et personnalisez facilement vos campagnes display',
    Cam: 'Performances de campagne améliorées',
    Message2: 'Atteignez vos objectifs publicitaires tout en restant rentable',
    Html: 'Bannière publicitaire HTML5 personnalisée',
    Message3: 'Interagissez avec votre audience grâce à des bannières HTML5 personnalisées',
    Flex: 'Plan de dépenses adaptatif',
    Message4: 'Affinez la distribution des publicités en fonction des dépenses et des résultats',
    Str: 'publicité de positionnement stratégique',
    Message5: 'Placez la bannière pour une visibilité maximale',
    Your: "Avantages pour l'annonceur",
    If: "Êtes-vous éditeur ? Inscrivez-vous maintenant !",
    Adver: 'Annonce du jour',
    Say: 'Commentaires des clients',
    Setup: 'Configurez votre campagne publicitaire de crypto-monnaie en quelques minutes',
    Reach: 'Attirer des millions de nouveaux clients',
    Across5: "Nos outils de suivi et d'optimisation vous aideront à améliorer votre efficacité marketing, tandis que votre gestionnaire de campagne veillera au bon déroulement de vos annonces du début à la fin.",
    tagMessage1: "Nous sommes ravis de travailler avec la formidable équipe d'ADX ! Ils font preuve d’un professionnalisme et d’une ponctualité exceptionnels, fournissant constamment d’excellents résultats. Leurs services augmentent considérablement l'exposition dont les projets de cryptographie ont besoin et ils s'efforcent constamment d'atteindre l'excellence dans leur travail",
    tagMessage2: "Grâce à notre vaste expérience et à nos informations précieuses dans la gestion de campagnes publicitaires sur ADX, Student Coin a réussi à se connecter avec un grand nombre d'investisseurs mondiaux. En seulement 12 mois depuis notre création, nous sommes fiers de figurer parmi les 300 premières crypto-monnaies au monde en termes de capitalisation boursière",
    tagMessage3: "Student Coin possède une expertise et un soutien étendus dans la gestion de campagnes publicitaires via ADX, créant ainsi une large base d'investisseurs mondiaux. En seulement 12 mois depuis notre création, nous nous sommes classés parmi les 300 premières crypto-monnaies par capitalisation boursière.",
    title1: "A. PDG de Wall Street Game",
    title2: "Daniel Directeur marketing",
    title3: "Daniel Directeur marketing",
    data1:"Devenez l'un de nos annonceurs à succès. En vous appuyant sur nos partenariats uniques avec les principales plateformes financières et de crypto-monnaie, ainsi que sur des conseils professionnels et une assistance personnalisée, vos plans marketing produiront rapidement des résultats extraordinaires",
  },
  foot: {
    abouttitle: 'À propos de nous',
    about1: 'À propos de nous',
    about2: 'Rencontrez nos partenaires',
    about3: 'Contactez-nous',
    SERVICEStitle: 'Services',
    services1: "ADX de l'annonceur",
    services2: "ADX de l'éditeur",
    services3: 'Marché ADX',
    HELPtitle: 'Aide',
    help1: 'blog',
    help2: 'FAQ',
    help3: 'Télécharger le kit média',
    help4: "Nos formats d'annonces",
    SOCIALPRESENCEtitle: 'Coopération Sociale',
    social1: 'Facebook',
    social2: 'Twitter',
    social3: 'YouTube',
    social4: 'LinkedIn',
  },
  contact: {
    sendTitle: 'Laissez-nous un message et commençons !',
    sendMessage1: 'Si vous êtes prêt à commencer à travailler ensemble, veuillez nous le faire savoir. Envoyez-nous un message via notre formulaire de contact et nous vous répondrons dans les plus brefs délais.',
    sendMessage2: "Si vous estimez que votre vie privée ou votre confidentialité a été violée par l'un de nos éditeurs ou annonceurs, ou si vous êtes témoin d'un comportement qui viole nos conditions générales, nous vous encourageons fortement à le signaler via notre outil de signalement d'abus.",
    sendTitle2: "Outil de signalement d'abus",
    contactTeam: 'Contactez notre équipe',
    reportAbuse: 'Signaler un abus',
    form1title1: 'Type de compte',
    form1input1: "Je suis un annonceur",
    form1input12: "Je suis l'éditeur",
    form1title2: 'Nom',
    form1input2: "Votre nom complet",
    form1title3: 'adresse e-mail',
    form1input3: "Votre adresse email",
    form1title4: 'Messagerie instantanée',
    form1input4: "Skype, Telegram ou numéro de téléphone (facultatif)",
    form1title5: 'Informations',
    form1input5: "Écrivez votre question ou demande ici...",
    submit: 'Envoyez votre message',
    form2title1: 'Type de rapport',
    form2input1: "Éditeur/Site Web",
    form2input12: "URL de l'annonceur/de la page de destination",
    form2input13: "Bannière publicitaire",
    form2input14: "Violation de la protection des données",
    form2title2: 'URL de site Web/de page violée',
    form2title3: "Capture d'écran/URL de preuve",
    form2input3: "https://prnt.sc/...(facultatif)",
    form2title4: 'Informations',
    form2input4: "Écrivez votre message ici pour nous aider à mieux comprendre le type de rapport...",
    AvailableSizes: 'Tailles disponibles:',
    Highlights: 'accent',
  },
  //常见问题
  fag: {
    General: 'Général',
    Advertisers: 'Annonceurs',
    Publishers: 'Éditeurs',
    //General
    Help: 'Comment pouvons-nous vous aider ?',
    SearchAnswer: 'Rechercher une question',
    ChangeEmail: 'Puis-je modifier mon adresse e-mail ?',
    EmailAnswer: "Vous ne pouvez modifier votre adresse e-mail qu'en faisant une demande à notre équipe via l'adresse e-mail actuellement utilisée dans votre compte.",
    ChangPassword: 'Comment puis-je changer mon mot de passe ?',
    Password1: "Vous pouvez le faire en allant dans l'onglet Paramètres du tableau de bord",
    changPassword: 'Changer le mot de passe',
    Password2: 'section pour changer le mot de passe.',
    HackedAccount: 'Que dois-je faire si je pense que mon compte a été piraté ?',
    HackedAnswer: 'Si vous avez des raisons de croire que votre compte a été compromis, veuillez modifier votre mot de passe immédiatement et contacter votre responsable de compte.',
    HaveAccounts: 'Puis-je avoir plusieurs comptes ?',
    HaveAccountsAnswer: 'Non, plusieurs comptes ne sont pas autorisés.',
    //Advertisers
    BecomeAdvertiser: 'Comment devenir annonceur ?',
    BecomeAdvertiserAnswer: 'Pour devenir annonceur, vous devez créer un compte. Nous vous contacterons ensuite pour discuter de tous les détails de votre événement.',
    MakeDeposit: 'Comment puis-je déposer ?',
    MakeDepositAnswer: "Vous pouvez effectuer un dépôt via l'outil de financement des dépôts dans la section Facturation du tableau de bord.",
    ProvideSupport: 'Fournissez-vous une assistance pour les activités de configuration ?',
    ProvideSupportAnswer: "Oui, notre équipe vous aidera tout au long du processus de création d'événement. Cela va du conseil sur la façon d'optimiser votre campagne à la conception de vos bannières pour obtenir les meilleurs résultats.",
    ProvideAssistance: 'Fournissez-vous une assistance pour la conception de bannières ?',
    ProvideAssistanceAnswer: 'Oui. Nos designers sont prêts à créer votre bannière à votre goût.',
    AdFormats: "Quels formats d'annonces sont disponibles ?",
    AdFormatsAnswer: "Certains des formats publicitaires disponibles incluent des bannières classiques, des bannières d'en-tête, des bannières autocollantes et des publicités natives. Vous pouvez consulter notre liste complète de formats d'annonces.",
    AdFormatsAnswer2: 'Ici.',
    Difference: "Quelle est la différence entre la notoriété d'une marque et un trafic de qualité ?",
    DifferenceAnswer: "Le forfait Premium offre du trafic vers des sites Web premium issus de la niche crypto. Cela se reflète également dans le CPM des deux forfaits, avec un CPM de 0,20 $ pour le trafic de notoriété de la marque.",
    SpecificCountries: 'Puis-je cibler des pays spécifiques ?',
    SpecificCountriesAnswer: "Oui, vous pouvez cibler votre audience en fonction du pays ou de l'appareil.",
    GoLive: 'Combien de temps faut-il pour que mon événement soit mis en ligne ?',
    GoLiveAnswer: "Après la soumission, votre campagne sera mise en ligne dans un délai d'un jour ouvrable. Cependant, le week-end ou les jours fériés, la mise en ligne de votre campagne peut prendre jusqu'à 48 heures.",
    GetRefund: 'Comment puis-je obtenir un remboursement ?',
    GetRefundAnswer: "Pour plus d'informations sur notre politique de remboursement, veuillez consulter notre",
    GetRefundAnswer2: 'Conditions générales.',
    //Publishers
    Pulish1: 'Comment devenir éditeur ?',
    Pulish2: 'Combien de sites Web puis-je ajouter ?',
    Pulish3: 'Quels types de sites Web sont acceptés ?',
    Pulish4: 'Quels sont vos tarifs ?',
    Pulish5: "Quels sont les frais de retrait d'argent ?",
    Pulish6: 'Vais-je payer en fonction des clics ou des impressions ?',
    Pulish7: 'Puis-je demander à mes utilisateurs de cliquer sur une annonce ?',
    Pulish8: "Pourquoi ai-je moins d'impressions sur mon tableau de bord que sur les autres réseaux ?",

    Pulish1Mess: "Pour devenir éditeur, vous devez créer un compte et soumettre votre site via le tableau de bord de l'éditeur. Une fois approuvée, nous commencerons à afficher la bannière sur votre site.",
    Pulish2Mess: "Il n'y a pas de limite au nombre de sites Web sur lesquels vous pouvez faire de la publicité.",
    Pulish3Mess: "En tant que réseau publicitaire financier et crypto-monnaie, ADX n'accepte que les sites Web et les projets liés aux crypto-monnaies dans son réseau.",
    Pulish4Mess: 'Les tarifs sont basés sur la qualité du site Web. Au cours de ce processus, nous prenons en compte divers facteurs tels que la conception du site Web, la qualité du contenu, etc.',
    Pulish5Mess: 'Aucun frais de retrait.',
    Pulish6Mess: 'Nous avons un modèle de paiement au coût pour mille impressions (CPM) pour les bannières et les publicités natives.',
    Pulish7Mess: "Non. Les utilisateurs ne doivent interagir avec les publicités que s’ils les intéressent. Exiger de vos utilisateurs qu'ils cliquent sur les publicités entraînera le paiement par les annonceurs d'un trafic de mauvaise qualité et pourra vous amener à être banni de notre réseau.",
    Pulish8Mess: "En tant qu'éditeur, vous gagnez de l'argent à chaque fois qu'un utilisateur consulte une bannière placée sur votre site. Cela signifie que si la bannière est placée en bas de la page et que le visiteur ne fait pas défiler la page aussi loin, la vue ne comptera pas. Cette mesure est mise en œuvre pour garantir que les annonceurs ne paient pour voir leurs annonces que lorsque les utilisateurs interagissent avec ou.",

  },
  formats: {
    title: "Nos formats d'annonces",
    DisplayCampaign: 'Campagne display',
    subtitlea: "Nos bannières classiques offrent un moyen simple d'attirer l'attention de votre public cible",
    Displayfeaturea: 'Visibilité accrue',
    Displayfeatureb: 'Accepter la bannière HTML5',
    Displayfeaturec: 'Mode de paiement CPM',
    Displayfeatured: 'Taux de clics moyen de 0,25 % à 0,30 %',
    CreateCampaign: 'Créer une campagne',
    PopupAd: 'Publicité intercalée',
    subtitleb: "Publicité plein écran affichée entre le chargement d'une page Web",
    Nativefeaturea: 'Excellent emplacement',
    Nativefeatureb: "Personnalisable via l'API publique",
    Nativefeaturec: 'Modèle de paiement CPM',
    Anchoradvertising: "publicité d'ancrage",
    subtitlec: 'Annonce fixée sur le bord supérieur ou inférieur de la page',
    Popunderfeaturea: 'Affichage rapide',
    Popunderfeatureb: 'Idéal pour les offres à opt-in unique',
    Popunderfeaturec: 'Modèle de paiement CPM',
    Popunderfeatured: 'Taux de clics moyen de 0,25 % à 0,30 %',
    DisplayCampaignMessage: 'Afficher la description de la campagne',
    PopupAdMessage: 'Description de la publicité intercalée',
    AnchoradvertisingMessage: 'Description de la publicité ancrée',
    AvailableSizes: 'Tailles disponibles:',
    Highlights: 'accent',
  }
}
export default fr