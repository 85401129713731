"use strict";

require("core-js/modules/es.array.push.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) {
  return (0, vue_2.pushScopeId)("data-v-9d3a1e1a"), n = n(), (0, vue_2.popScopeId)(), n;
};
var _hoisted_1 = {
  class: "accordion",
  id: "accordionPanelsStayOpenExample"
};
var _hoisted_2 = ["id"];
var _hoisted_3 = ["data-bs-target", "aria-controls"];
var _hoisted_4 = ["id", "aria-labelledby"];
var _hoisted_5 = {
  class: "accordion-body"
};
var _hoisted_6 = {
  class: "item"
};
var _hoisted_7 = {
  class: "message"
};
var _hoisted_8 = {
  class: "item"
};
var _hoisted_9 = {
  class: "message"
};
var _hoisted_10 = {
  class: "item"
};
var _hoisted_11 = {
  class: "message"
};
var vuex_1 = require("vuex");
exports.default = (0, vue_1.defineComponent)({
  __name: 'PublishersFaq',
  setup: function (__props) {
    var store = (0, vuex_1.useStore)();
    store.commit('faq/Publishers');
    return function (_ctx, _cache) {
      return (0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [(0, vue_2.createElementVNode)("div", _hoisted_1, [((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(store).state.faq.Publishers, function (item) {
        return (0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
          class: "accordion-item",
          key: item.title
        }, [(0, vue_2.createElementVNode)("h2", {
          class: "accordion-header",
          id: 'panelsStayOpen-' + item.heading
        }, [(0, vue_2.createElementVNode)("button", {
          class: "accordion-button collapsed",
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": '#panelsStayOpen-' + item.collapse,
          "aria-expanded": "true",
          "aria-controls": 'panelsStayOpen-' + item.collapse
        }, (0, vue_2.toDisplayString)(_ctx.$t(item.title)), 9, _hoisted_3)], 8, _hoisted_2), (0, vue_2.createElementVNode)("div", {
          id: 'panelsStayOpen-' + item.collapse,
          class: "accordion-collapse collapse",
          "aria-labelledby": 'panelsStayOpen-' + item.heading
        }, [(0, vue_2.createElementVNode)("div", _hoisted_5, [(0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", _hoisted_6, [(0, vue_2.createElementVNode)("div", _hoisted_7, (0, vue_2.toDisplayString)(_ctx.$t(item === null || item === void 0 ? void 0 : item.message)), 1)], 512), [[vue_2.vShow, item.id != 8 && item.id != 12]]), (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", _hoisted_8, [(0, vue_2.createElementVNode)("div", _hoisted_9, [(0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('fag.AdFormatsAnswer')) + " ", 1), (0, vue_2.createElementVNode)("span", {
          style: {
            "color": "#3A6FF8",
            "cursor": "pointer"
          },
          onClick: _cache[0] || (_cache[0] = function ($event) {
            return _ctx.$router.push('/formats');
          })
        }, (0, vue_2.toDisplayString)(_ctx.$t('fag.AdFormatsAnswer2')), 1)])], 512), [[vue_2.vShow, item.id == 8]]), (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", _hoisted_10, [(0, vue_2.createElementVNode)("div", _hoisted_11, [(0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('fag.GetRefundAnswer')) + " ", 1), (0, vue_2.createElementVNode)("span", {
          style: {
            "color": "#3A6FF8",
            "cursor": "pointer"
          },
          onClick: _cache[1] || (_cache[1] = function ($event) {
            return _ctx.$router.push('/all/Terms');
          })
        }, (0, vue_2.toDisplayString)(_ctx.$t('fag.GetRefundAnswer2')), 1)])], 512), [[vue_2.vShow, item.id == 12]])])], 8, _hoisted_4)]);
      }), 128))])]);
    };
  }
});