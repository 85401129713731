import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'swiper/css/bundle'

// 加载公共样式
import './assets/styles/less/common.less';
// 加载国际化组件
import I18n from './i18n';
// 加载element-plus组件
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 加载bootstrap样式
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
const app = createApp(App)
app.use(store).use(router).use(ElementPlus, { size: 'small', zIndex: 3000, }).use(I18n).mount('#app')



